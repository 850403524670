import axios from "axios";
import "katex/dist/katex.min.css";
import React, { useEffect, useRef, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkMath from "remark-math";
import styles from "./chatroom.module.css";

import ReactMarkdown from "react-markdown";
import LogoSmall from "../Logo/LogoSmall";

const BASE_API_ADDRESS = process.env.REACT_APP_API_GATEWAY_REST;
const BASE_WSS_ADDRESS = process.env.REACT_APP_API_GATEWAY_WS;

const WAKE_UP_TUTOR_URL = BASE_API_ADDRESS + "/wake_up_tutor";

const wake_up_tutor = async () => {
    try {
        console.log("waking up tutor");
        await axios.get(WAKE_UP_TUTOR_URL, { params: { user_id: "wake_up" } });
    } catch (error) {
        console.log(error);
        return null;
    }
};

const high_traffic_message =
    "Please be patient for a moment, we have recently made technical adjustments to improve the quality of Learnboost. Therefore, we need to re-analyze your document and set it up again for the Tutor AI. This only needs to be done once. You will automatically receive the answer to your last question in a few seconds here in the chat, no additional action is required from your side.";

const sendIcon = encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M6.99811 10.2467L7.43298 11.0077C7.70983 11.4922 7.84825 11.7344 7.84825 12C7.84825 12.2656 7.70983 12.5078 7.43299 12.9923L7.43298 12.9923L6.99811 13.7533C5.75981 15.9203 5.14066 17.0039 5.62348 17.5412C6.1063 18.0785 7.24961 17.5783 9.53623 16.5779L15.8119 13.8323C17.6074 13.0468 18.5051 12.654 18.5051 12C18.5051 11.346 17.6074 10.9532 15.8119 10.1677L9.53624 7.4221C7.24962 6.42171 6.1063 5.92151 5.62348 6.45883C5.14066 6.99615 5.75981 8.07966 6.99811 10.2467Z" stroke="#222222"/>
</svg>`
);

const sendIconURL = `data:image/svg+xml,${sendIcon}`;

const ClipBoardIcon = ({ handleClick }) => (
    <svg
        className={styles.clipBoardIcon}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        onClick={() => {
            handleClick();
        }}>
        <rect x="7.08325" y="7.0835" width="7.08333" height="7.08333" rx="2" stroke="#222222" />
        <path
            d="M9.91659 4.7312V4.7312C9.91659 3.68312 9.06695 2.8335 8.01888 2.8335H6.37492C4.93074 2.8335 4.20865 2.8335 3.69505 3.18896C3.49719 3.3259 3.32566 3.49743 3.18872 3.6953C2.83325 4.20889 2.83325 4.93098 2.83325 6.37516V7.79183C2.83325 7.90789 2.83325 7.96593 2.83566 8.01496C2.88613 9.04237 3.70771 9.86395 4.73512 9.91442C4.78415 9.91683 4.84219 9.91683 4.95825 9.91683V9.91683"
            stroke="#222222"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const SoundIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
            d="M2.23727 9.86753C1.73222 9.02579 1.73222 7.97421 2.23727 7.13247V7.13247C2.39138 6.87561 2.64671 6.69567 2.94044 6.63693L4.13967 6.39708C4.21112 6.38279 4.27552 6.34447 4.32216 6.2885L4.96259 5.51999C6.14515 4.10092 6.73643 3.39138 7.26409 3.58242C7.79175 3.77346 7.79175 4.69707 7.79175 6.54428L7.79175 10.4557C7.79175 12.3029 7.79175 13.2265 7.26409 13.4176C6.73643 13.6086 6.14515 12.8991 4.96259 11.48L4.32216 10.7115C4.27552 10.6555 4.21112 10.6172 4.13967 10.6029L2.94044 10.3631C2.64671 10.3043 2.39138 10.1244 2.23727 9.86753V9.86753Z"
            stroke="#222222"
        />
        <path
            d="M11.0043 5.99583C11.665 6.6566 12.0379 7.55174 12.0416 8.48621C12.0452 9.42068 11.6795 10.3187 11.0239 10.9847"
            stroke="#222222"
            strokeLinecap="round"
        />
        <path
            d="M13.9236 4.49322C14.9808 5.55046 15.5774 6.98269 15.5833 8.47784C15.5892 9.97299 15.0039 11.4099 13.955 12.4754"
            stroke="#222222"
            strokeLinecap="round"
        />
    </svg>
);

const CheckIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#494949"
        className={styles.clipBoardIcon}>
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
    </svg>
);

const handleWebSocketRequest = (
    document_id,
    user_id,
    query,
    selectedLanguage,
    onMessageRecieved,
    onError
) => {
    const ws = new WebSocket(BASE_WSS_ADDRESS);
    const TIMEOUT_DURATION = 3 * 60 * 1000; // 1 minute
    let timeoutId;

    ws.onopen = () => {
        console.log("Websocket connection opened");
        ws.send(
            JSON.stringify({
                action: "tutorai_v5",
                document_id: document_id,
                user_id: user_id,
                query: query,
                language: selectedLanguage,
            })
        );
        console.log(`Sent Tutor Ai request`);
        timeoutId = setTimeout(() => {
            ws.close();
            onError(new Error("Timeout waiting for WebSocket response"));
        }, TIMEOUT_DURATION);
    };

    ws.onmessage = (event) => {
        clearTimeout(timeoutId);
        const data = JSON.parse(event.data);
        onMessageRecieved(data);
    };

    ws.onerror = (error) => {
        clearTimeout(timeoutId);
        console.error("WebSocket error: ", error);
        onError(error);
    };

    ws.onclose = (event) => {
        console.log("Websocket Connection closed");
        clearTimeout(timeoutId);
        if (!event.wasClean) {
            onError(new Error("WebSocket closed with unexpected error"));
        }
    };

    return ws;
};

function ChatRoom({
    document_id,
    user_id,
    chat,
    setChat,
    selectedLanguage,
    toggleUpgradeModal,
    wake_up_document_processor,
    setCurrentAreas,
    setCurrentPageReference,
    showInfoBanner,
}) {
    const dummy = useRef();
    const textareaRef = useRef(null);
    const formRef = useRef(null);
    const [formHeight, setFormHeight] = useState(0);

    const [formValue, setFormValue] = useState("");
    const [formKey, setFormKey] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    /*useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, [formKey]);*/

    useEffect(() => {
        if (formRef.current) {
            setFormHeight(formRef.current.offsetHeight);
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevent the default action of inserting a new line
            sendMessage(e); // Call the sendMessage function
        }
    };

    const generate_answer = (query, user_id, file_id, selectedLanguage) => {
        let isNewMessage = true;
        setIsLoading(true);
        setChat((prevMessages) => [...prevMessages, { text: "", uid: -1 }]);
        const ws = handleWebSocketRequest(
            document_id,
            user_id,
            query,
            selectedLanguage,
            (reply) => {
                // On message received callback
                if (typeof reply === "string" && reply === high_traffic_message) {
                    setChat((prevMessages) => {
                        return [...prevMessages, { text: reply + " ", uid: -1 }];
                    });
                    return;
                }
                setIsLoading(false);
                if (typeof reply === "string") {
                    setChat((prevMessages) => {
                        if (isNewMessage) {
                            isNewMessage = false; // Subsequent messages will update this message
                            return [...prevMessages, { text: reply, uid: -1 }];
                        } else {
                            // Append to the last message
                            const lastMessage = prevMessages[prevMessages.length - 1];
                            return [
                                ...prevMessages.slice(0, -1),
                                { ...lastMessage, text: lastMessage.text + reply },
                            ];
                        }
                    });
                }
                if (reply.statusCode && reply.statusCode === 200) {
                    ws.close(); // Close the WebSocket connection on receiving end signal
                    setTimeout(() => {
                        isNewMessage = true;
                    }, 100); // Reset for the next message
                }
            },
            (error) => {
                // onError callback
                console.log(error);
                setIsLoading(false);
                ws.close(); // Ensure WebSocket is closed on error
            }
        );

        return ws; // Return the WebSocket object for potential later use
    };

    const handleChange = (e) => {
        const textarea = e.target;
        const singleLineHeight = 24; // Adjust this based on the line-height of your textarea
        const maxHeight = 62; // The max height for the textarea

        // Remove any previously set height to get the actual scrollHeight
        textarea.style.height = "auto";

        // Determine if the content is more than one line by comparing scrollHeight to the line height
        const isMultiLine = textarea.scrollHeight > singleLineHeight;

        // If content is more than one line, adjust the height and padding as necessary
        if (isMultiLine) {
            textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
            textarea.style.overflowY = textarea.scrollHeight > maxHeight ? "auto" : "hidden";
            // Adjust the padding here if you want different padding for multiple lines
        } else {
            // Set height to auto and reset any scrolling
            textarea.style.height = "auto";
            textarea.style.overflowY = "hidden";
            // Reset the padding to vertically center the text for a single line
            textarea.style.paddingTop = "0px";
            textarea.style.paddingBottom = "0px";
        }

        // Set the value of formValue state
        setFormValue(textarea.value);
    };

    const sendMessage = (e) => {
        e.preventDefault();
        if (formValue.trim() === "") {
            return;
        }

        const uid = user_id;

        setChat((prevMessages) => [...prevMessages, { text: formValue, uid }]);
        setFormValue("");
        setFormKey((prevKey) => prevKey + 1);

        generate_answer(formValue, user_id, document_id, selectedLanguage);
    };

    useEffect(() => {
        // Assuming `styles.main` is the class for your main chat container
        const chatContainer = document.querySelector(`.${styles.main}`);

        if (chatContainer) {
            // Wait for the browser to finish any pending layout and rendering work
            setTimeout(() => {
                // Scroll to the bottom of the container
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }, 0); // A timeout of 0 can sometimes be enough to defer the action until after render
        }
    }, [chat.length]); // Depend on chat.length to trigger this effect when new messages are added

    // displayed while waiting for a message from the Backend
    const LoaderMessage = () => (
        <div className={`${styles.message} ${styles.tutorMessage}`}>
            <div className={styles.tutorImg}>
                <LogoSmall />
                <div className={styles.tutorLogoDivider} />
            </div>
            <p className={styles.tutorText} translate="no">
                <BeatLoader size={8} color={"#123abc"} loading={true} />
            </p>
        </div>
    );

    return (
        <>
            <main className={styles.main}>
                {chat &&
                    chat.map((msg) => (
                        <ChatMessage
                            key={msg.id}
                            message={msg}
                            toggleUpgradeModal={toggleUpgradeModal}
                            setCurrentAreas={setCurrentAreas}
                            setCurrentPageReference={setCurrentPageReference}
                            showInfoBanner={showInfoBanner}
                        />
                    ))}

                {isLoading && <LoaderMessage />}

                <span ref={dummy}></span>
            </main>

            <form ref={formRef} key={formKey} className={styles.form} onSubmit={sendMessage}>
                <textarea
                    ref={textareaRef}
                    className={styles.input}
                    value={formValue}
                    onFocus={() => {
                        wake_up_tutor();
                        wake_up_document_processor();
                    }}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Ask a question"
                    rows="1" // Start with a single line.
                ></textarea>

                <button className={styles.button} type="submit" disabled={!formValue}>
                    <img className={styles.sendIcon} src={sendIconURL} alt="send icon" />
                </button>
            </form>
        </>
    );
}

function convertLineBreaksToBr(text) {
    return text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}

const ChatMessage = React.memo(ChatMessageComponent);

function ChatMessageComponent(props) {
    const { text, uid } = props.message;
    const messageOrigin = uid === -1 ? "tutor" : "user";
    const messageClass = messageOrigin === "user" ? styles.sentMessage : styles.tutorMessage;
    const textClass = messageOrigin === "user" ? styles.sentText : styles.tutorText;

    const setAreas = props.setCurrentAreas;
    const setCurrentPageReference = props.setCurrentPageReference;
    const showInfoBanner = props.showInfoBanner;
    const [showCheckmark, setShowCheckmark] = useState(false);

    const cleanText = (message) => {
        let text = message.replace(/(<sup[^>]*>[^<]*<\/sup>)+$/, "");

        // Regular expression to match other HTML tags and capture their content
        let htmlTagRegex = /<[^>]*>([^<]*)<\/[^>]*>/g;

        // Replace all other HTML tags with their inner content
        text = text.replace(htmlTagRegex, "$1");

        // Regular expression to replace the Markdown image template with the caption
        let markdownImageRegex = /\[!\[([^\]]*)]\([^)]*\)]\([^)]*\)/g;

        // Replace the Markdown image template with just the caption
        return text.replace(markdownImageRegex, "$1");
    };

    const toggleCheckmark = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            console.log(cleanText(text));
            navigator.clipboard
                .writeText(cleanText(text))
                .then(() => {
                    console.log("Text copied to clipboard successfully!");
                    setShowCheckmark(true);
                    setTimeout(() => {
                        setShowCheckmark(false);
                    }, 3000);
                })
                .catch((err) => {
                    showInfoBanner("Failed to copy text: ");
                    console.log(err);
                });
        } else {
            showInfoBanner("Clipboard API not available");
        }
    };

    const BotIcons = () => {
        return (
            <>
                {showCheckmark ? (
                    <CheckIcon />
                ) : (
                    <ClipBoardIcon handleClick={toggleCheckmark}></ClipBoardIcon>
                )}
            </>
        );
    };

    let processedText = convertLineBreaksToBr(text);

    // Check for the special string
    const upgradeString = "Click here to upgrade now";

    if (text.includes(upgradeString) && uid === -1) {
        // Split the text and insert the clickable part
        const parts = text.split(upgradeString);
        processedText = (
            <>
                {parts[0]}
                <span
                    onClick={props.toggleUpgradeModal}
                    style={{ color: "blue", cursor: "pointer" }}>
                    {upgradeString}
                </span>
                {parts[1]}
            </>
        );
    }

    if (!text.trim()) {
        return null;
    }

    const displayUpgradeMessage = !!(text.includes(upgradeString) && messageOrigin === "tutor");

    const customHeading = {
        h1: ({ node, ...props }) => <h1 className={styles.h1LineHeight} {...props} />,
    };

    const customImage = {
        a: ({ node, ...props }) => {
            if (props.href && props.children && props.children.props && props.children.props.src) {
                // If the <a> tag has a href attribute and wraps around an <img> tag, render it without the href attribute
                return React.cloneElement(props.children, { href: null });
            } else {
                // If the <a> tag doesn't wrap around an <img> tag, render it normally
                return <a {...props} />;
            }
        },
        img: ({ node, ...props }) => (
            <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img
                    alt=""
                    {...props}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "256px",
                        minWidth: "100px",
                        pointerEvents: "none",
                    }}
                />

                {props.alt && (
                    <figcaption
                        style={{
                            textDecoration: "none",
                            cursor: "default",
                            textAlign: "center",
                            color: "gray",
                        }}>
                        {props.alt}
                    </figcaption>
                )}
            </figure>
        ),
        abbr: ({ node, ...props }) => {
            return (
                <abbr
                    onClick={() => {
                        setCurrentPageReference(props.children);
                        const Areas = JSON.parse(props["data-id"]);
                        Areas.forEach((area) => {
                            area["pageIndex"] = parseInt(props.children) - 1;
                        });
                        setAreas(Areas);
                    }}
                    className={styles.reference}
                    {...props}
                />
            );
        },
        sup: ({ node, ...props }) => {
            return "";
        },
    };

    return (
        <>
            <div className={`${styles.message} ${messageClass}`}>
                {messageOrigin === "tutor" ? (
                    <div className={styles.tutorImg}>
                        <LogoSmall />
                        <div className={styles.tutorLogoDivider} />
                    </div>
                ) : (
                    ""
                )}

                {displayUpgradeMessage ? (
                    <div className={textClass} translate="no">
                        {processedText}
                    </div>
                ) : (
                    <div className={textClass} translate="no">
                        <ReactMarkdown
                            components={{ ...customHeading, ...customImage }}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex, rehypeRaw]}>
                            {text}
                        </ReactMarkdown>
                        {messageOrigin === "tutor" && <BotIcons />}
                    </div>
                )}
            </div>
        </>
    );
}

export default ChatRoom;
