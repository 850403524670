const LogoSmall = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="26" viewBox="0 0 11 16" fill="none">
            <path
                d="M3.76832 0.393188V11.5214C3.76832 11.9111 3.85933 12.194 4.04135 12.37C4.22338 12.5431 4.52372 12.6288 4.9454 12.6288H10.1149L10.2666 15.1325C8.79823 15.2935 6.67614 15.3733 3.9018 15.3733C2.73685 15.3733 1.87072 15.0844 1.30493 14.5066C0.737626 13.9288 0.45549 13.1585 0.45549 12.1955V0.393188H3.76832Z"
                fill="#0254F2"/>
        </svg>
    )
}

export default LogoSmall