import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "../Button_v2/Button";
import Icon from "../Icon";
import LogoLarge from "../Logo/LogoLarge";
import LogoSmall from "../Logo/LogoSmall";
import LanguageButton from "../Toolbar_LangButton/LanguageButton";
import RepetitionButton from "../Toolbar_RepetitionsButton/RepetitionsButton";
import buttonStyles from "./../Button_v2/Button_V2.module.css";
import styles from "./Toolbar.module.css";

import Confetti from "react-confetti";

/* Old toolbar:
 * <Toolbar
 *      isShowingCheckIcon={isShowingCheckIcon} -> shows a check-icon after summary was saved
 * />
 */

const Toolbar = ({
    currentAppMode,
    onAppModeChange,
    currentLanguage,
    onLanguageChange,
    onClearChat,
    onFeedbackButtonClick,
    onSaveSummary,
    isSavingSummary,
    isGeneratingPDF,
    download_summary_pdf,
    onUpgrade,
    onToggleFileManager,
    onProfileClick,
    isPaidUser,
    isExportingFlashCards,
    download_flashcards,
    toggleResetModal,
    isStudyModeActive,
    toggleStudyMode,
    numberOfRepetitions,
    setNumberOfRepetitions,
    isShowingCheckIcon,
}) => {
    const modes = [
        {
            key: "tutor",
            label: "Tutor AI",
            context: {
                button_language: {},
                button_trash: {},
            },
        },
        {
            key: "summary",
            label: "Summary",
            context: {
                button_save: {},
                button_download: {},
            },
        },
        {
            key: "cards",
            label: "Cards",
            context: {
                button_hat: {},
                button_download: {},
            },
        },
    ];
    const languages = [
        {
            key: "German",
            label: "DE",
        },
        {
            key: "English",
            label: "EN",
        },
    ];

    const [isRepetitionDropdownOpen, setRepetitionDropwdownOpen] = useState(false);
    const repetition_modes = [
        { label: "1" },
        { label: "2" },
        { label: "3" },
        { label: "4" },
        { label: "5" },
    ];

    const handleRepetitionChange = (label) => {
        setNumberOfRepetitions(label);
        setRepetitionDropwdownOpen(false);
    };

    let saveSummaryIcon = <Icon name="DiskIcon" />;
    if (isSavingSummary) {
        saveSummaryIcon = <ClipLoader size={21} color="#000" className={styles.spinner} />;
    }
    if (isShowingCheckIcon) {
        saveSummaryIcon = <Icon name="CheckIcon" />;
    }

    const [showConfetti, setShowConfetti] = useState(false);

    const handleEasterEgg = () => {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 4000);
    };

    const viewportHeight = window.screen.height;
    const gravity = 0.1;

    return (
        <>
            {showConfetti && (
                <Confetti
                    height={viewportHeight}
                    gravity={gravity}
                    recycle={false}
                    numberOfPieces={1000}></Confetti>
            )}
            <div className={styles.Toolbar}>
                <div className={styles.Toolbar_left}>
                    <a
                        href="https://www.learnboost.tech"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.logo}>
                        <span className={styles.hide_mobile}>
                            <LogoLarge />
                        </span>
                        <span className={styles.show_mobile}>
                            <LogoSmall />
                        </span>
                    </a>
                    <button
                        className={styles.btn_filemanager}
                        onClick={onToggleFileManager && onToggleFileManager}>
                        <Icon name="FolderIcon" />
                    </button>
                    <button
                        className={styles.btn_feedback}
                        onClick={onFeedbackButtonClick && onFeedbackButtonClick}>
                        Feedback
                    </button>
                </div>

                <ul className={styles.modeSelector}>
                    {modes.map((mode) => (
                        <li
                            className={mode.key === currentAppMode ? styles.active : ""}
                            key={mode.key}
                            onClick={() => {
                                onAppModeChange && onAppModeChange(mode.key);
                            }}>
                            {mode.label}
                        </li>
                    ))}
                </ul>

                <div className={styles.Toolbar_right}>
                    {currentAppMode === "tutor" && (
                        <>
                            <LanguageButton
                                languages={languages}
                                onLanguageChange={onLanguageChange}
                                selectedLanguage={currentLanguage}
                            />
                            <button
                                className={styles.btn_clear_chat}
                                onClick={onClearChat && onClearChat}>
                                <Icon name="TrashIcon" />
                            </button>
                        </>
                    )}

                    {/* TODO: add "checkmark" to button after summary was updated */}
                    {currentAppMode === "summary" && (
                        <>
                            <button
                                className={styles.btn_save_summary}
                                onClick={onSaveSummary && onSaveSummary}>
                                {saveSummaryIcon}
                            </button>

                            <button
                                className={styles.btn_download_summary}
                                onClick={download_summary_pdf && download_summary_pdf}>
                                {isGeneratingPDF ? (
                                    <ClipLoader size={21} color="#000" className={styles.spinner} />
                                ) : (
                                    <Icon name="DownloadIcon" />
                                )}
                            </button>
                        </>
                    )}

                    {/*{currentAppMode === "cards" && !isStudyModeActive && isExportingFlashCards === true && (*/}
                    {/*    <div className={styles.wrapper}>*/}
                    {/*        <ClipLoader color='#256EFF' className={styles.cliploader}/>*/}
                    {/*    </div>)}*/}

                    {currentAppMode === "cards" && (
                        <button
                            className={
                                styles.btn_studymode +
                                " " +
                                (isStudyModeActive ? styles.active : "")
                            }
                            onClick={toggleStudyMode}>
                            <Icon name="StudyModeIcon" />
                        </button>
                    )}

                    {currentAppMode === "cards" && isStudyModeActive && (
                        <>
                            <RepetitionButton
                                title={numberOfRepetitions}
                                dropdownContent={repetition_modes}
                                onDropdownClick={handleRepetitionChange}
                                isOpen={isRepetitionDropdownOpen}
                                onToggle={() =>
                                    setRepetitionDropwdownOpen(!isRepetitionDropdownOpen)
                                }
                                selectedRepetitions={numberOfRepetitions}
                            />
                            <button className={styles.btn_reset} onClick={toggleResetModal}>
                                <Icon name="HistoryIcon" />
                            </button>
                        </>
                    )}

                    {currentAppMode === "cards" && !isStudyModeActive && (
                        <button className={styles.btn_download_cards} onClick={download_flashcards}>
                            {isExportingFlashCards ? (
                                <ClipLoader size={21} color="#000" className={styles.spinner} />
                            ) : (
                                <Icon name="DownloadIcon" />
                            )}
                        </button>
                    )}
                    {!isPaidUser ? (
                        <Button color={buttonStyles.btn_primary} onClick={onUpgrade && onUpgrade}>
                            Upgrade now
                        </Button>
                    ) : (
                        <Button
                            color={buttonStyles.btn_primary}
                            onClick={handleEasterEgg}
                            cssClass={styles.hide_mobile}>
                            Unlimited&nbsp;&nbsp;🎉
                        </Button>
                    )}
                    <button
                        className={styles.btn_profile}
                        onClick={onProfileClick && onProfileClick}>
                        <Icon name="ProfileIcon" />
                    </button>
                </div>
            </div>
            <div className={styles.Toolbar_bottom}></div>
        </>
    );
};

Toolbar.propTypes = {};
Toolbar.defaultProps = {};
export default Toolbar;
