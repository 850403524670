import React, { useState } from 'react';
import styles from './errormodal.module.css'; 

const ErrorModal = ({ isVisible, message, statusCode, onClose, submitSupportRequest }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);


  if (!isVisible) return null;

  return (
    <div className={styles.errorOverlay}>
      <div className={styles.errorMessage}>
        <h2>Error {statusCode}: Oops! Something went wrong...</h2>
        <p>{message}</p>
        <button className={styles.button} onClick={onClose}>Close</button>
        <button className={`${styles.button} ${isSubmitting ? styles.deactivated : ''} ${isSubmitting ? styles.submitting : ''} ${submitSuccess ? styles.success : ''}`} onClick={async () => {
                                    if (!isSubmitting && !submitSuccess) {
                                        setIsSubmitting(true);
                                        await submitSupportRequest(message, statusCode);
                                        setIsSubmitting(false);
                                        setSubmitSuccess(true);
                                        setTimeout(() => {setSubmitSuccess(false)
                                        onClose()}, 5000);
                                    }
                                }}>
            {isSubmitting ? 'Submitting...' : submitSuccess ? 'Submitted!' : 'Send to support'}
        </button>
      </div>
    </div>
  );
};
export default ErrorModal;
