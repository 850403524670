const StudyModeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M3.84299 9.12534L11.1877 5.86101C11.7049 5.63118 12.2951 5.63118 12.8123 5.86101L20.157 9.12534C20.4817 9.26962 20.4817 9.73038 20.157 9.87466L12.8123 13.139C12.2951 13.3688 11.7049 13.3688 11.1877 13.139L3.84299 9.87466C3.51835 9.73038 3.51835 9.26962 3.84299 9.12534Z"
                stroke="#33363F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.5 9.5V13.5" stroke="#33363F" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M6.5 11.5V16.5C6.5 16.5 7 18.5 12 18.5C17 18.5 17.5 16.5 17.5 16.5V11.5" stroke="#33363F"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default StudyModeIcon;