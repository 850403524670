import styles from "./Button_V2.module.css";

const Button = ({ children, color, onClick, cssClass }) => {
    return (
        <button className={styles.btn + " " + color + " " + cssClass} onClick={onClick && onClick}>
            {children}
        </button>
    );
};

export default Button;
