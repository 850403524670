import React from 'react';
import styles from './plancard.module.css';
import {useCheckout} from '@memberstack/react';

function splitByNewLine(text) {
    return text.split('\n').map((part, index, array) => (
        <span key={index}>
      {part}
            {index !== array.length - 1 && <br/>}
    </span>
    ));
}

function PlanCard({plan}) {
    const checkout = useCheckout();

    return (
        <div className={styles.cardItem}> {/* Corresponds to card-item-4 in the image */}
            <div
                className={styles.cardItemContent}> {/* Corresponds to card-item-content pricing-item-content in the image */}
                <div className={styles.pricingItemContent}>
                    <div className={styles.pricingTitle}> {/* "pricing-title" and "Unlimited" in the image */}
                        {plan.title}
                    </div>
                    <div className={styles.pricingMainContent}> {/* "pricing-main-content" in the image */}
                        <div className={styles.productItemPrice}>
                            <span className={styles.currencySymbol}>{plan.currencySymbol}</span>
                            {plan.price}
                        </div>
                        <div className={styles.pricingItemPriceDesc}> {/* "pricing-item-price-desc" in the image */}
                            {splitByNewLine(plan.duration)}
                        </div>
                    </div>
                    <div className={styles.pricingItemDescription}> {/* "pricing-item-description-3" in the image */}
                        {plan.description}
                    </div>
                </div>
                <div className={styles.pricingItemButtonHolder}>
                    <a onClick={() => checkout({priceId: plan.price_id})} className={styles.checkoutButton}>Upgrade
                        now</a>
                </div>
            </div>
        </div>
    );
}

export default PlanCard;
