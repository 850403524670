import styles from "./InlineCardModal.module.css";
import React from "react";

const InlineCardModal = ({children}) => {
    return (
        <div className={styles.inlineCard}>
            <div className={styles.uploadTextSubtitle}>
                {children}
            </div>
        </div>
    );
}

export default InlineCardModal;