import styles from "./FlashCardButton.module.css";
import React from "react";


const CorrectIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
    </svg>
);

const IncorrectIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
    </svg>
);

const UncoverIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M12 5C6.55576 5 3.53109 9.23425 2.45554 11.1164C2.23488 11.5025 2.12456 11.6956 2.1367 11.9836C2.14885 12.2716 2.27857 12.4598 2.53799 12.8362C3.8182 14.6935 7.29389 19 12 19C16.7061 19 20.1818 14.6935 21.462 12.8362C21.7214 12.4598 21.8511 12.2716 21.8633 11.9836C21.8754 11.6956 21.7651 11.5025 21.5445 11.1164C20.4689 9.23425 17.4442 5 12 5Z"
            stroke="white" stroke-width="1.5"/>
        <circle cx="12" cy="12" r="3" stroke="white" stroke-width="1.5"/>
    </svg>
);

const RestartIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M9.03586 8.54361L5.31815 8.54361L5.31815 4.8259" stroke="white" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M5.1381 13.7113C5.54862 15.2434 6.4673 16.5906 7.74363 17.5323C9.01997 18.474 10.5783 18.9543 12.1633 18.8946C13.7483 18.8348 15.266 18.2385 16.4678 17.2034C17.6696 16.1683 18.4843 14.7557 18.7783 13.197C19.0723 11.6384 18.8282 10.0261 18.0861 8.62428C17.3439 7.22248 16.1477 6.11428 14.6934 5.48123C13.239 4.84818 11.6128 4.72781 10.0812 5.13985C8.54947 5.55189 7.20313 6.4719 6.26269 7.74917"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

const FlashCardButton = ({type, onClick}) => {
    let content = "";
    let icon = "";
    switch (type) {
        case "correct":
            content = "";
            icon = CorrectIcon;
            break;
        case "incorrect":
            content = "";
            icon = IncorrectIcon;
            break;
        case "restart":
            content = "Restart";
            icon = RestartIcon;
            break;
        case "uncover":
            content = "Uncover";
            icon = UncoverIcon;
            break;
        default:
            content = "";
            icon = "";
            break;
    }
    return (
        <button className={styles.btn + " " + styles[type]} onClick={onClick && onClick}>
                {icon} {content}
            </button>
        );
    }
;

export default FlashCardButton;
