import React, { useState } from 'react';
import styles from './downloadmodal.module.css';

const DownloadModal = ({ isVisible, onClose, downloadLink }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const startDownload = () => {
    window.open(downloadLink, '_blank'); // Example of triggering a download
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(downloadLink).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Reset the message after 2 seconds
    }, () => {
      setCopySuccess('Failed to copy');
    });
  };

  if (!isVisible) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.window}>
        <header className={styles.header}>
          <span>Download Link</span>
          <button onClick={onClose}>&times;</button>
        </header>
        <div className={styles.content}>
          <div className={styles.downloadLinkContainer}>
            <input type="text" value={downloadLink} readOnly className={styles.downloadLinkInput}/>
            <div className={styles.buttonContainer}>
              <button onClick={startDownload} className={styles.actionButton}>Start Download</button>
              <button onClick={copyToClipboard} className={styles.actionButton}>Copy</button>
            </div>
          </div>
          {copySuccess && <div className={styles.copySuccessMessage}>{copySuccess}</div>}
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
