import FolderUpLightIcon from "./FolderUpLightIcon";

const Icon = ({name}) => {

    switch (name) {

        case "FolderUpLightIcon":
            return <FolderUpLightIcon/>;
        default:
            return ""
    }
};

export default Icon;