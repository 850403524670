import React, { useState, useEffect } from 'react';
import lottie from 'lottie-web';
import styles from './feedbackmodal.module.css';
import * as animationDataA from './likeAnimation.json';



const Star = ({ selected = false, hovered = false, onClick, onMouseEnter, onMouseLeave }) => {
    return (
      <span
        className={`${styles.star} ${selected ? styles.selected : ''} ${hovered ? styles.hovered : ''}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        &#9733;
      </span>
    );
  }
  
const Rating = ({ totalStars = 5, rating, setRating, hoverAt, setHoverAt }) => {

    return (
        <div className={styles.rating}>
        {[...Array(totalStars)].map((_, i) => (
            <Star
            key={i}
            selected={i < rating}
            hovered={i < hoverAt && hoverAt > 0}
            onClick={() => setRating(i + 1)}
            onMouseEnter={() => setHoverAt(i + 1)}
            onMouseLeave={() => setHoverAt(0)}
            />
        ))}
        </div>
    );
}
  

const FeedbackModal = ({isVisible, onClose, submitFeedback}) => {
    const [rating, setRating] = useState(0);
    const [hoverAt, setHoverAt] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    useEffect(() => {
        let animation;
        if (submitSuccess && isVisible) {
            animation = lottie.loadAnimation({
                container: document.getElementById('likeAnimation'),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                width: 50,
                height: 50,
                animationData: animationDataA,
            });
        }
        return () => animation?.destroy();
    }, [submitSuccess, isVisible]);
    

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    if(!isVisible){
        return null
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.window}>
                <div className={styles.toolbar}>
                    <div className={styles.title}>Feedback</div>
                    <div className={styles.controlbar}>
                        <div className={styles.button} onClick={() => onClose()}>
                            <span style={{ transform: 'translateY(-2px)' }}>&times;</span>
                        </div>
                    </div>
                </div>
                {submitSuccess ? (
                    <div className={styles.animationcontainer}>
                        <div id="likeAnimation" className={styles.likeanimation}>
                        </div>
                        <span className={styles.liketext}>Thank you, we appreciate your feedback!</span>
                    </div>
                ) : (
                    <>
                        <div className={styles.feedbackcontainer}>
                            <textarea 
                                className={styles.feedback} 
                                placeholder="(Optional) Leave your feedback here in your preferred language..."
                                value={feedback}
                                onChange={handleFeedbackChange}
                            />
                        </div>           
                        <div className={styles.ratingcontainer}>
                            <div className={styles.rating}>
                                <Rating rating={rating} setRating={setRating} hoverAt={hoverAt} setHoverAt={setHoverAt}/>
                            </div>
                            <div
                                className={`${styles.submitbutton} ${rating === 0 || isSubmitting ? styles.deactivated : ''} ${isSubmitting ? styles.submitting : ''} ${submitSuccess ? styles.success : ''}`}
                                onClick={async () => {
                                    if (rating > 0 && !isSubmitting && !submitSuccess) {
                                        setIsSubmitting(true); // Start submitting
                                        await submitFeedback(feedback, rating); // Simulate feedback submission
                                        setIsSubmitting(false);
                                        setSubmitSuccess(true); // Set success state
                                        setTimeout(() => {setSubmitSuccess(false)
                                        onClose()}, 5000); // Reset after 5 seconds
                                    }
                                }}
                            >
                                <span>{isSubmitting ? 'Submitting...' : submitSuccess ? 'Submitted!' : 'Submit Feedback'}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );    
}

export default FeedbackModal;

