import React, { useState } from 'react';
import PlanCard from '../PlanCard';
import styles from './planinfo.module.css';

const unlimited_flex_europe = {
  title: "Unlimited Flex",
  price: "14,95",
  currencySymbol: "€",
  duration: "monthly",
  description: "Full feature access, unlimited summaries, flashcards and Tutor AI messages on a flexible monthly basis.",
  price_id: "prc_unlimited-flex-6d3s010e",
}

const unlimited_semester_europe = {
  title: "Unlimited Semester",
  price: "6,65",
  currencySymbol: "€",
  duration: "monthly\n for 6 months",
  description: "The best price for using Learnboost for a full semester. Unlimited summaries, flashcards and Tutor AI messages with all features included.",
  price_id: "prc_learnboost-unlimited-semester-f-r-6-65-monatlich-f-r-6-monate--jj4001vx",
}

const unlimited_flex_us = {
  title: "Unlimited Flex",
  price: "14,95",
  currencySymbol: "$",
  duration: "monthly",
  description: "Full feature access, unlimited summaries, flashcards and Tutor AI messages on a flexible monthly basis.",
  price_id: "prc_learnboost-unlimited-flex-kpad0lim",
}

const unlimited_semester_us = {
  title: "Unlimited Semester",
  price: "6,65",
  currencySymbol: "$",
  duration: "monthly\n for 6 months",
  description: "The best price for using Learnboost for a full semester. Unlimited summaries, flashcards and Tutor AI messages with all features included.",
  price_id: "prc_learnboost-unlimited-semester-9rae0l5d",
}

const boxIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    style={{ width: '24px', height: '24px' }}  // Inline style for width and height
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
  </svg>
);

const plansEurope = [unlimited_flex_europe, unlimited_semester_europe];
const plansAmerica = [unlimited_flex_us, unlimited_semester_us];

const checkboxItems = [
  "Unlimited AI Summaries",
  "Unlimited Tutor AI Messages",
  "No Word or Page Limit",
  "Fully integrated PDF Reader",
  "Text Editor with PDF Export",
  "Access Upcoming Features first"
];

const AmericanCountries = ["AU", "CA", "US"]

function PlanInfo({isUpgradeModalOpen, toggleUpgradeModal, countryCode }) {

  const plans = AmericanCountries.includes(countryCode) ? plansAmerica : plansEurope

  const firstGroup = checkboxItems.slice(0, 3);
  const secondGroup = checkboxItems.slice(3);

  const planCardsOverlay = isUpgradeModalOpen ? (
    <div className={styles.overlay}>
      <div className={styles.window}>
        <header className={styles.header}>
          <span>Choose Your Plan</span>
          <button onClick={toggleUpgradeModal}>&times;</button>
        </header>
        <div className={styles.content}>
           <div className={styles.headline}> Upgrade Now and Save <br></br>
           <span className={styles.gradientText}>Unlimited Time and Effort</span>
           </div>
          <div className={styles.plans}>
          {plans.map(plan => (
            <PlanCard 
              key={plan.id}
              isOpen={true} 
              closeOverlay={toggleUpgradeModal}
              plan={plan}
              currencySymbol={plan.currencySymbol}
            />
          ))}
          </div>
          <div className={styles.checkBoxes}>
          <div className={styles.checkboxGroup}>
          {firstGroup.map((item, index) => (
            <div key={index} className={styles.checkboxItem}>
              {React.cloneElement(boxIcon)}
              <span className={styles.checkboxText}>{item}</span>
            </div>
          ))}
        </div>
        <div className={styles.checkboxGroup}>
          {secondGroup.map((item, index) => (
            <div key={index} className={styles.checkboxItem}>
              {React.cloneElement(boxIcon)}
              <span className={styles.checkboxText}>{item}</span>
            </div>
          ))}
          </div>
        </div>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div>
      {planCardsOverlay}
    </div>
  );
}

export default PlanInfo;
