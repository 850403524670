import styles from "./ModeTitleBar.module.css";
import React, {useState} from "react";


const ModeTitleBar = ({
    title,
    onTitleChange,
    placeHolder= ""
}) => {

    const [formValue, setFormValue] = useState(title);

    const handleChange = (e) => {
        setFormValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey && e.target.value !== title) {
            onTitleChange(e.target.value);
            e.preventDefault(); // Prevent the default action of inserting a new line
        }
    };

    const handleBlur = (e) => {
        if (e.target.value !== title) {
            onTitleChange(e.target.value);
        }
    };

    return (
        <div className={styles.title}>
            <input
                className={styles.input}
                value={formValue}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                onBlur={(e) => handleBlur(e)}
                onFocusOut={(e) => handleBlur(e)}
                onMouseLeave={(e) => handleBlur(e)}
                placeholder={placeHolder}>
            </input>
        </div>
    );
}

export default ModeTitleBar;