import React from 'react';
import styles from './OverlayModal/YesOrNoOverlayModal.module.css';
import OverlayModal from './OverlayModal/OverlayModal';

const DeleteButtonFileManager = ({isVisible, onClose, onReset, headerMessage, message}) => {
    return (
        <OverlayModal title={headerMessage} onClose={onClose} isVisible={isVisible}>
            {message.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
            <div className={styles.buttonContainer}>
                <button onClick={onReset} className={`${styles.actionButton} ${styles.redButton}`}>Accept</button>
                <button onClick={onClose} className={`${styles.actionButton} ${styles.blueButton}`}>Cancel</button>
            </div>
        </OverlayModal>
    );
};

export default DeleteButtonFileManager;
