import React, { useState } from 'react';
import styles from './summarybutton.module.css';

const ChevronDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24" fill="#FFFFFF">
    <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
  </svg>
);


const SparkIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#FFFFFF" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" />
      </svg>
    );
};


function SummarizeButton({document_id, handleSummaryCreation, appLanguage, showInfoBanner}) {
  const [showDropdownConfig, setShowDropdownConfig] = useState(false);
  const [showDropdownPages, setShowDropdownPages] = useState(false);
  const [showDropdownLanguage, setShowDropdownLanguage] = useState(false);
  const [config, setConfig] = useState("Bullets - detailed");
  const [pages, setPages] = useState("All Pages");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  console.log(appLanguage);

  const modes = {
    "Bullets - detailed": "DETAILED_IN_BULLET_POINTS",
    "Bullets - short (Beta)": "SHORT_IN_BULLET_POINTS",
    "Text - short (Beta)": "SHORT_IN_TEXT"
  }

  const languages = {
    "DE": "German",
    "EN": "English"
  }
  
  const languageKey = Object.keys(languages).find(key => languages[key] === appLanguage);
  const [language, setLanguage] = useState(languageKey || "English");
  const [showDropdownImages, setShowDropdownImages] = useState(false);
  const [includeImages, setIncludeImages] = useState(true);

  const toggleIncludeImages = (e) => {
    setIncludeImages(e);
  };


  

  const getPageInterval = () => {
    if(firstPage === "" && lastPage === "" || firstPage === "1" && lastPage === ""){
      return null
    }
    if(firstPage === "" && lastPage !== ""){
      return [1, lastPage];
    }
    if(firstPage !== "" && lastPage === ""){
      return [firstPage, "MAX"];
    }

      return [firstPage, lastPage];
  }

  const generate = () => {
    if (lastPage !== "" && firstPage !== "" && parseInt(lastPage) < parseInt(firstPage)) {
      console.log(lastPage)
      console.log(firstPage)
      showInfoBanner("Invalid range for pages: Please choose the first page number to be smaller than the last");
      return;
    }
    //console.log(languages[language])
    //console.log(modes[config])
    //console.log(getPageInterval(pages))
    handleSummaryCreation(document_id, languages[language], modes[config], getPageInterval(pages), includeImages);
  }

  const updatePages = (firstPage, lastPage) => {
    if(firstPage === "" && lastPage === "" || firstPage === "1" && lastPage === ""){
      setPages("All Pages");
      return;
    }
    if(firstPage === "" && lastPage !== ""){
      setPages(`Until Page ${lastPage}`);
      return;
    }
    if(firstPage !== "" && lastPage === ""){
      setPages(`After Page ${firstPage}`);
      return;
    }

      setPages(`${firstPage} to ${lastPage}`);
  }

  const handleFirstPageChange = (event) => {
    const value = event.target.value;
    if(value === "0"){
      showInfoBanner("Please enter a positive number.")
      return;
    }
    if (!isNaN(value)) {
      setFirstPage(value);
      updatePages(value, lastPage);
    } else {
      console.log("Please input numbers only for the page number.");
    }
  };

  const handleLastPageChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setLastPage(value);
      updatePages(firstPage, value);
    } else {
      console.log("Please input numbers only for the page number.");
    }
  };
  
    return (
      <div className={styles.uploadContainer}>
        <div className={styles.iconCircle}>
        <span className={styles.uploadIcon}>
            <SparkIcon/>
        </span>
        </div>
        <h3 className={styles.uploadTextSubtitle}>
          Generate a complete summary 
          <br></br>
          with one click
        </h3>
        <div className={styles.uploadArea}>
        <div className={styles.generateButton}>
          <button className={styles.generateBtn} onClick={generate}>
             Generate now!
          </button>
        </div>
          <div className={styles.buttons}>
          <div className={styles.dropdown}>
            <button className={styles.uploadBtn} onClick={() => setShowDropdownConfig(!showDropdownConfig)}>
              {config}
              <ChevronDownIcon/>
            </button>
            {showDropdownConfig && (
              <div className={styles.dropdownContent}>
                {Object.entries(modes).map(([label, item], index) => (
                  <button
                    key={index}
                    className={styles.uploadBtn}
                    onClick={() => { setConfig(label); setShowDropdownConfig(false); }}
                  >
                    {label}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className={styles.dropdown}>
            <button className={styles.uploadBtn} onClick={() => setShowDropdownPages(!showDropdownPages)}>
              {pages}
              <ChevronDownIcon/>
            </button>
            {showDropdownPages && (
              <div className={styles.dropdownContentPages}>
                <input
                  className={styles.pageInput}
                  value={firstPage}
                  onChange={handleFirstPageChange}
                  placeholder="1"
                />
                to
                <input
                  className={styles.pageInput}
                  value={lastPage}
                  onChange={handleLastPageChange}
                  placeholder="100"
                />
              </div>
            )}
          </div>
          <div className={styles.dropdown}>
            <button className={styles.uploadBtn} onClick={() => setShowDropdownLanguage(!showDropdownLanguage)}>
              {language} <ChevronDownIcon />
            </button>
            {showDropdownLanguage && (
              <div className={styles.dropdownContent}>
                {Object.entries(languages).map(([code, name], index) => (
                  <button
                    key={index}
                    className={styles.uploadBtn}
                    onClick={() => { setLanguage(code); setShowDropdownLanguage(false); }}
                  >
                    {code}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.generateButton}>
            <button className={styles.imageBtn} onClick={() => setShowDropdownImages(!showDropdownImages)}>
              {includeImages ? "Images On" : "Images Off"} <ChevronDownIcon></ChevronDownIcon>
            </button>
        </div>
        <div className={styles.dropdownImages}>
            {showDropdownImages && (
              <div className={styles.dropdownContent}>
                <button
                  className={styles.generateBtn}
                  onClick={() => { toggleIncludeImages(true); setShowDropdownImages(false); }}
                >
                  Images On
                </button>
                <button
                  className={styles.generateBtn}
                  onClick={() => { toggleIncludeImages(false); setShowDropdownImages(false); }}
                >
                  Images Off
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
}


export default SummarizeButton;
