import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "../Button_v2/Button";
import FinishCard from "../FlashCard/FinishCard";
import Flashcard from "../FlashCard/Flashcard";
import InlineCardModal from "../InlineCardModal/InlineCardModal";
import ModeTitleBar from "../ModeTitleBar/ModeTitleBar";
import YesOrNoOverlayModal from "../OverlayModal/YesOrNoOverlayModal";
import buttonStyles from "./../Button_v2/Button_V2.module.css";
import FlashCardButton from "./FlashCardButton";
import styles from "./FlashCardContainer.module.css";

const initialValue = `<p>Content of your card</p>
<ul>
    <li>Example item in the list</li>
    <li>More examples</li>
</ul>`;

const BASE_API_ADDRESS = process.env.REACT_APP_API_GATEWAY_REST;
const SAVE_FLASHCARDS_ENDPOINT = BASE_API_ADDRESS + "/generate_upload_link_flashcards";
const SAVE_FLASHCARDS_TITLE_ENDPOINT = BASE_API_ADDRESS + "/save_flashcards_title";

const save_flashcards = async (
    document_id,
    user_id,
    flashcards,
    flashcardsLogs,
    flashcardStatus,
    uploadFileToS3
) => {
    try {
        //console.log("document_id", document_id)
        //console.log("user_id", user_id)
        //console.log(instructions);
        const response = await axios.get(SAVE_FLASHCARDS_ENDPOINT, {
            params: {
                document_id: document_id,
                user_id: user_id,
            },
        });
        //console.log(response.data);
        const presignedUrl = response.data.presigned_url;
        console.log(presignedUrl);

        const cards = {
            status: flashcardStatus,
            flashcards: flashcards,
            logs: flashcardsLogs,
        };

        const flashcardsJson = JSON.stringify(cards);
        const file = new Blob([flashcardsJson], { type: "application/json" });

        await uploadFileToS3(presignedUrl, file);
        console.log("Flashcards Saved");
    } catch (error) {
        console.log(error);
    }
};

const save_flashcards_title = async (document_id, flashcards_title) => {
    try {
        await axios.get(SAVE_FLASHCARDS_TITLE_ENDPOINT, {
            params: {
                document_id: document_id,
                flashcards_title: flashcards_title,
            },
        });
        console.log("Flashcards Title Saved");
    } catch (error) {
        console.log(error);
    }
};

const AddCardContainer = ({ id, modifyCard }) => {
    const useLazyLoad = (ref, options = {}) => {
        const [isVisible, setIsVisible] = useState(false);

        useEffect(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(ref.current); // Stop observing once loaded
                    }
                });
            }, options);

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.disconnect();
                }
            };
        }, [ref, options]);

        return isVisible;
    };

    const ref = useRef();
    const isVisible = useLazyLoad(ref);

    return (
        <div ref={ref} className={styles.ref}>
            {isVisible && (
                <div className={styles.button} onClick={() => modifyCard(id, "createCard")}>
                    <div className={styles.plusHorizontal}></div>
                    <div className={styles.plusVertical}></div>
                </div>
            )}
        </div>
    );
};

const FlashCardContainer = ({
    document_id,
    user_id,
    uploadFileToS3,
    isStudyModeActive,
    numberOfRepetitions,
    flashcardLogs,
    setFlashCardLogs,
    isResetModalOpen,
    toggleResetModal,
    flashcards_title,
    setFlashCardsTitle,
    flashcards,
    setFlashCards,
    flashcardStatus,
    isPaidUser,
    toggleUpgradeModal,
    handleFlashcardCreation,
}) => {
    const [instructions, setInstructions] = useState([]);
    const [savingCardIds, setSavingCardIds] = useState([]);
    const [finishedSavingCardIds, setFinishedSavingCardIds] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isShowingAnalytics, setIsShowingAnalytics] = useState(false);
    const [successCounts, setSuccessCounts] = useState({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
    const [didOneMove, setDidOneMove] = useState(false);
    const [correctCards, setCorrectCards] = useState(0);

    const getSuccessCount = () => {
        let correctAnswers = 0;
        for (let index = 0; index < flashcards.length; index++) {
            correctAnswers += flashcards[index].correct;
        }

        const targetRepetitions = numberOfRepetitions * flashcards.length;
        return ((correctAnswers / targetRepetitions) * 100).toFixed(2);
    }

    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    const [sortedAndShuffledFlashcards, setSortedAndShuffledFlashcards] = useState(
        Object.values(
            flashcards.reduce((acc, card) => {
                acc[card.correct] = acc[card.correct] || [];
                acc[card.correct].push(card);
                return acc;
            }, {})
        ).map((group) => shuffleArray(group)).flat()
    );

    const [currentCard, setCurrentCard] = useState(sortedAndShuffledFlashcards[0]);

    useEffect(() => {
        setCurrentCard(sortedAndShuffledFlashcards[0]);
        if (sortedAndShuffledFlashcards[0].correct === numberOfRepetitions && didOneMove === true) {
            const currentDate = new Date();
            const dateString = currentDate.toLocaleDateString();
            const newLog = { mode: numberOfRepetitions, date: dateString };
            setFlashCardLogs((prevLogs) => [...prevLogs, newLog]);
            modifyCard(null, "addLog", null, null, newLog);
        }
    }, [sortedAndShuffledFlashcards]);

    useEffect(() => {
        setIsFinished(sortedAndShuffledFlashcards[0].correct >= numberOfRepetitions);
    }, [sortedAndShuffledFlashcards, numberOfRepetitions]);

    useEffect(() => {
        let newCounts = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

        for (let i = 0; i <= 5; i++) {
            newCounts[i] = flashcards.filter((card) => card.correct === i).length;
        }

        //console.log(newCounts)
        setSuccessCounts(newCounts);
    }, [sortedAndShuffledFlashcards]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (instructions.length > 0) {
                //console.log(JSON.stringify(instructions));
                save_flashcards(
                    document_id,
                    user_id,
                    flashcards,
                    flashcardLogs,
                    flashcardStatus,
                    uploadFileToS3
                );
                setInstructions([]);
                //console.log(finishedSavingCardIds);
                setSavingCardIds([]);
                setTimeout(() => {
                    setFinishedSavingCardIds([]);
                }, 2000);
            }
        }, 2000);

        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, [instructions]);

    const onFlashcardsTitleChange = (title) => {
        setFlashCardsTitle(title);
        save_flashcards_title(document_id, title);
    };

    const toggleFullView = () => {
        setShowAnswer(!showAnswer);
    };

    const toggleShowAnalytics = () => {
        setIsShowingAnalytics(!isShowingAnalytics);
    };

    const handleCardMarked = (cardId, increment) => {
        // Find the index of the card
        const cardIndex = sortedAndShuffledFlashcards.findIndex((card) => card.id === cardId);
        if (cardIndex === -1) return; // card not found

        // Update the card's 'correct' value
        const updatedCard = {
            ...sortedAndShuffledFlashcards[cardIndex],
            correct: sortedAndShuffledFlashcards[cardIndex].correct + increment,
            incorrect: sortedAndShuffledFlashcards[cardIndex].incorrect + (1 - increment),
        };

        // Remove the card from its current position
        const newFlashcards = sortedAndShuffledFlashcards.filter((_, index) => index !== cardIndex);
        //console.log("Flashcards:", sortedAndShuffledFlashcards);

        const firstMatchingScoreIndex = newFlashcards.findIndex(
            (card) => card.correct === updatedCard.correct
        );

        const startIndex =
            firstMatchingScoreIndex === -1 && increment > 0
                ? newFlashcards.length
                : firstMatchingScoreIndex;
        const endIndex = newFlashcards.findIndex((card) => card.correct > updatedCard.correct);
        const targetIndexRange = endIndex === -1 ? newFlashcards.length : endIndex;

        //console.log("Start Index:",startIndex);
        //console.log("End Index:", endIndex);
        //console.log("Target Range:", targetIndexRange);

        const fixedPosition = 0;
        // Calculate the absolute random position. For last two cards choose a random number between 0 and 1
        const randomPosition =
            startIndex === 0 && endIndex === 1
                ? Math.random() < 0.5
                    ? 0
                    : 1
                : startIndex + Math.floor(Math.random() * (targetIndexRange - startIndex));

        console.log("Final Index:", randomPosition);
        // Insert the card at the new position using ternary operator. If hit inccorect on last card to be learned show that card again
        newFlashcards.splice(
            startIndex === -1 && endIndex === 0 ? fixedPosition : randomPosition,
            0,
            updatedCard
        );

        // Update the state or variable holding the flashcards
        setSortedAndShuffledFlashcards(newFlashcards); // Assuming you're using React state
    };

    const handleRestart = () => {
        const newCards = shuffleArray(
            sortedAndShuffledFlashcards.map((card) => ({ ...card, correct: 0, incorrect: 0 }))
        );
        setSortedAndShuffledFlashcards(newCards);
    };

    //This function does a hard comparison of the two contents from cards
    const isContentEqual = (first, second) => {
        return JSON.stringify(first) === JSON.stringify(second);
    };

    const modifyCard = (id, action, headline, content, newLog) => {
        const index = flashcards.findIndex((card) => card.id === id);

        const updateInstructions = (actionType, cardDetails) => {
            setInstructions((prevInstructions) => [
                ...prevInstructions,
                { action: actionType, details: cardDetails },
            ]);
        };

        if (action === "saveCard") {
            const card = flashcards.find((card) => card.id === id);
            if (headline === card.headline && isContentEqual(content, card.content)) {
                console.log("Nothing to change");
                return;
            }
            setSavingCardIds((prevCards) => [...prevCards, id]);
            setFinishedSavingCardIds((prevCards) => [...prevCards, id]);
            const newFlashcards = flashcards.map((card) => {
                if (card.id === id) {
                    return { ...card, headline: headline, content: content };
                }
                return card;
            });
            setFlashCards(newFlashcards);
            updateInstructions("saveCard", { id, headline, content });
        }

        if (action === "deleteCard") {
            const newFlashcards = flashcards.filter((card) => card.id !== id);
            setFlashCards(newFlashcards);
            updateInstructions("deleteCard", { id });
            if (newFlashcards.length === 0) {
                const newId = uuidv4();
                const newCard = { id: newId, headline: "", content: initialValue };
                const newFlashcards = [newCard];
                setFlashCards(newFlashcards);
                updateInstructions("createCard", { id: id, newId: newId });
            }
        }

        if (action === "createCard") {
            const newId = uuidv4();
            const newCard = {
                id: newId,
                headline: "",
                content: initialValue,
                correct: 0,
                incorrect: 0,
            };
            const newFlashcards = [...flashcards];
            newFlashcards.splice(index + 1, 0, newCard);
            setFlashCards(newFlashcards);
            updateInstructions("createCard", { id: id, new_id: newId });
        }

        if (action === "increaseCorrect") {
            handleCardMarked(id, 1);
            toggleFullView();
            setDidOneMove(true);

            const newFlashcards = flashcards.map((card) => {
                if (card.id === id) {
                    return { ...card, correct: card.correct + 1 };
                }
                return card;
            });
            setFlashCards(newFlashcards);
            updateInstructions("increaseCorrect", { id: id });
        }

        if (action === "increaseIncorrect") {
            handleCardMarked(id, 0);
            toggleFullView();
            setDidOneMove(true);

            const newFlashcards = flashcards.map((card) => {
                if (card.id === id) {
                    return { ...card, incorrect: card.incorrect + 1 };
                }
                return card;
            });
            setFlashCards(newFlashcards);
            updateInstructions("increaseIncorrect", { id: id });
        }

        if (action === "reset") {
            handleRestart();

            const newFlashcards = flashcards.map((card) => ({ ...card, correct: 0, incorrect: 0 }));
            setFlashCards(newFlashcards);
            updateInstructions("reset", {});
        }

        if (action === "addLog") {
            updateInstructions("addLog", { log: newLog });
        }
    };

    return (
        <>
            {!isStudyModeActive && (
                <div className={styles.container}>
                    <ModeTitleBar
                        title={flashcards_title}
                        onTitleChange={onFlashcardsTitleChange}
                        placeHolder={"Title of your deck"}
                    />
                    {flashcards.map((card, index) => (
                        <React.Fragment key={card.id}>
                            <Flashcard
                                card={card}
                                modifyCard={modifyCard}
                                savingCardIds={savingCardIds}
                                finishedSavingCardIds={finishedSavingCardIds}
                                cardNumber={index + 1}
                                numberOfCards={flashcards.length}
                            />
                            <AddCardContainer id={card.id} modifyCard={modifyCard} />
                        </React.Fragment>
                    ))}
                    {flashcardStatus === "exceeded limit" && !isPaidUser && (
                        <InlineCardModal>
                            <p>Those were your first 5 sets of flashcards</p>
                            <p>Upgrade now to generate unlimited sets of flashcards</p>
                            <Button color={buttonStyles.btn_primary} onClick={toggleUpgradeModal}>
                                Upgrade now!
                            </Button>
                        </InlineCardModal>
                    )}
                    {flashcardStatus === "incomplete" && !isPaidUser && (
                        <InlineCardModal>
                            <p>
                                Those were the flashcards for the first 1000 words of your document
                            </p>
                            <p>Upgrade now to generate flashcards for the full document</p>
                            <Button color={buttonStyles.btn_primary} onClick={toggleUpgradeModal}>
                                Upgrade now!
                            </Button>
                        </InlineCardModal>
                    )}
                    {(flashcardStatus === "incomplete" || flashcardStatus === "exceeded limit") &&
                        isPaidUser && (
                            <InlineCardModal>
                                <h3>Congratulations for upgrading to a premium plan</h3>
                                <p>Choose the language of your full set of flashcards:</p>
                                <Button
                                    color={buttonStyles.btn_primary}
                                    onClick={() =>
                                        handleFlashcardCreation(
                                            document_id,
                                            "English",
                                            null,
                                            null,
                                            true
                                        )
                                    }>
                                    English
                                </Button>
                                <Button
                                    color={buttonStyles.btn_primary}
                                    onClick={() =>
                                        handleFlashcardCreation(
                                            document_id,
                                            "German",
                                            null,
                                            null,
                                            true
                                        )
                                    }>
                                    German
                                </Button>
                                <p></p>
                            </InlineCardModal>
                        )}
                </div>
            )}

            {isStudyModeActive && flashcards.length > 0 && (
                <div className={styles.containerStudyMode}>
                    <div className={styles.deckTitle}>
                        {flashcards_title || "Title of your deck"}
                    </div>

                    {isShowingAnalytics && (
                        <div className={styles.analytics}>
                            <div className={styles.smallAnalyticsTitle}>Info</div>
                            <div className={styles.smallAnalyticsText}>
                                Total Cards: {flashcards.length}
                            </div>
                            <div className={styles.smallAnalyticsText}>
                                Successfully learned:{" "}
                                {getSuccessCount()} %
                            </div>
                            <div className={styles.smallAnalyticsTitle}>Analytics</div>
                            <>
                                {Object.entries(successCounts).map(([key, value]) => (
                                    <div className={styles.smallAnalyticsText} key={key}>
                                        {key}x successfully learned: {value}/{flashcards.length} (
                                        {flashcards.length === 0
                                            ? 0
                                            : Math.floor((value / flashcards.length) * 100)}
                                        %)
                                    </div>
                                ))}
                            </>
                            <div className={styles.smallAnalyticsTitle}>Logs</div>
                            <>
                                {flashcardLogs.slice(-5).map((log, index) => (
                                    <div key={index} className={styles.smallAnalyticsLog}>
                                        Last Time finished in mode Repeat {log.mode}x on:{" "}
                                        {log.date || "N/A"}
                                    </div>
                                ))}
                            </>
                        </div>
                    )}

                    <div className={styles.cardContainer}>
                        {isFinished ? (
                            <FinishCard numberOfRepetitions={numberOfRepetitions} />
                        ) : (
                            <Flashcard
                                key={currentCard.id}
                                card={currentCard}
                                isStudyModeActive={isStudyModeActive}
                                modifyCard={modifyCard}
                                savingCardIds={savingCardIds}
                                finishedSavingCardIds={finishedSavingCardIds}
                                cardNumber={
                                    flashcards.findIndex((card) => card.id === currentCard.id) + 1
                                }
                                numberOfCards={flashcards.length}
                                numberOfRepetitions={numberOfRepetitions}
                                showAnswer={showAnswer}
                            />
                        )}
                        <div className={styles.menuContainer}>
                            {isFinished && (
                                <FlashCardButton
                                    type={"restart"}
                                    onClick={toggleResetModal}></FlashCardButton>
                            )}

                            {!isFinished && showAnswer && (
                                <>
                                    <FlashCardButton
                                        type={"correct"}
                                        onClick={() => {
                                            modifyCard(currentCard.id, "increaseCorrect");
                                        }}></FlashCardButton>
                                    <FlashCardButton
                                        type={"incorrect"}
                                        onClick={() => {
                                            modifyCard(currentCard.id, "increaseIncorrect");
                                        }}></FlashCardButton>
                                </>
                            )}
                            {!isFinished && !showAnswer && (
                                <FlashCardButton
                                    type={"uncover"}
                                    onClick={toggleFullView}></FlashCardButton>
                            )}
                        </div>
                    </div>

                    <div className={styles.stats}>
                        <span className={styles.dataTitle}>Total cards: {flashcards.length}</span>
                        <span className={styles.dataTitle}></span>
                        <span className={styles.dataTitle}>
                            Successfully learned:{" "}
                            {getSuccessCount()}
                            %
                        </span>
                        <span className={styles.dataTitle}></span>
                        <span className={styles.analyticsTitle} onClick={toggleShowAnalytics}>
                            Click here for analytics
                        </span>
                    </div>
                </div>
            )}

            {isResetModalOpen && (
                <YesOrNoOverlayModal
                    isVisible={isResetModalOpen}
                    title={"Reset Progress"}
                    onClose={toggleResetModal}
                    onCancel={toggleResetModal}
                    onAccept={() => {
                        modifyCard(null, "reset");
                        toggleResetModal();
                    }}>
                    Are you sure you want to reset your progress for this card deck? <br />
                    This action cannot be undone.
                </YesOrNoOverlayModal>
            )}
        </>
    );
};

export default FlashCardContainer;
