const LogoLarge = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="26" viewBox="0 0 120 26"
         fill="none">
        <path
            d="M5.76832 5.39307V16.5213C5.76832 16.911 5.85933 17.1939 6.04135 17.3699C6.22338 17.5429 6.52372 17.6287 6.9454 17.6287H12.1149L12.2666 20.1324C10.7982 20.2934 8.67614 20.3732 5.9018 20.3732C4.73685 20.3732 3.87072 20.0843 3.30493 19.5065C2.73763 18.9287 2.45549 18.1583 2.45549 17.1954V5.39307H5.76832Z"
            fill="#0254F2"/>
        <path
            d="M20.7405 16.046H16.8634C16.9635 16.8254 17.1986 17.349 17.5672 17.6169C17.9389 17.8817 18.5532 18.0141 19.4117 18.0141C20.56 18.0141 21.8964 17.9358 23.4223 17.7794L23.7257 19.7655C22.6093 20.2996 21.0469 20.5659 19.0416 20.5659C17.0227 20.5659 15.5847 20.1356 14.7277 19.272C13.8691 18.4053 13.4414 16.9774 13.4414 14.9868C13.4414 12.9013 13.857 11.4404 14.6913 10.6053C15.5286 9.7672 16.9332 9.34741 18.9081 9.34741C20.695 9.34741 21.9874 9.65436 22.7852 10.2682C23.5861 10.8791 23.9942 11.812 24.0109 13.0669C24.0109 14.0629 23.7515 14.8092 23.2342 15.3057C22.72 15.7993 21.8888 16.046 20.7405 16.046ZM16.7967 14.1261H19.8486C20.2399 14.1261 20.5054 14.0328 20.6434 13.8432C20.7799 13.6552 20.8497 13.3798 20.8497 13.0187C20.8497 12.5177 20.7178 12.1701 20.4553 11.9775C20.1959 11.7819 19.7166 11.6826 19.0173 11.6826C18.1876 11.6826 17.6218 11.8451 17.3185 12.1701C17.0151 12.4921 16.8406 13.1436 16.7967 14.1261Z"
            fill="#0254F2"/>
        <path
            d="M26.3844 12.0257L26.081 9.865C28.0104 9.52044 29.8762 9.34741 31.6812 9.34741C33.1329 9.34741 34.2007 9.64082 34.8849 10.2261C35.5674 10.8084 35.9103 11.8195 35.9103 13.2594V20.3071H33.3559L33.0282 18.6882C31.9558 19.94 30.6346 20.5659 29.0662 20.5659C28.0347 20.5659 27.1913 20.2981 26.536 19.7595C25.8808 19.2178 25.5531 18.464 25.5531 17.4965V16.4132C25.5531 15.5676 25.8444 14.9055 26.4268 14.427C27.0093 13.9501 27.8163 13.7108 28.8477 13.7108H32.6824V13.2354C32.6657 12.6907 32.5383 12.3251 32.3001 12.14C32.0605 11.9519 31.5993 11.8571 30.9167 11.8571C29.723 11.8571 28.2122 11.9143 26.3844 12.0257ZM28.7385 16.6539V17.0632C28.7385 17.7974 29.1587 18.1645 30.0006 18.1645C30.8743 18.1645 31.7677 17.82 32.6824 17.1294V15.6368H29.7821C29.0859 15.6533 28.7385 15.9919 28.7385 16.6539Z"
            fill="#0254F2"/>
        <path
            d="M46.1723 9.34741L45.8446 12.3085H44.9709C44.258 12.3085 43.1628 12.5538 41.6824 13.0428V20.3071H38.4545V9.64834H40.9603L41.2455 11.2071C42.7138 9.96732 44.1655 9.34741 45.6019 9.34741H46.1723Z"
            fill="#0254F2"/>
        <path
            d="M50.3306 9.64834L50.5733 11.2252C52.1265 9.97334 53.6586 9.34741 55.1724 9.34741C56.1432 9.34741 56.8849 9.60019 57.3991 10.1057C57.9164 10.6083 58.1758 11.3426 58.1758 12.3085V20.3071H54.9722V13.3016C54.9722 12.8291 54.8948 12.5011 54.7416 12.3206C54.5914 12.14 54.3199 12.0497 53.9286 12.0497C53.2445 12.0497 52.2494 12.3732 50.9434 13.0187V20.3071H47.7155V9.64834H50.3306Z"
            fill="#0254F2"/>
        <path
            d="M65.1739 5.17651L64.6946 8.87188C64.5763 9.79573 64.373 10.6383 64.0818 11.3997C65.5046 10.044 66.9229 9.3654 68.3351 9.3654C69.6411 9.3654 70.533 9.79874 71.0108 10.6654C71.4917 11.5291 71.5781 12.9991 71.2717 15.077C70.9957 17.0962 70.4299 18.5196 69.5728 19.3501C68.7143 20.1777 67.4144 20.5899 65.6715 20.5899C64.8008 20.5899 63.7875 20.5087 62.6317 20.3492C61.4789 20.1927 60.5657 19.9926 59.8953 19.7473L61.9703 5.17651H65.1739ZM64.0151 13.5423L63.3658 18.1886C64.2911 18.2171 64.8493 18.2307 65.0405 18.2307C66.0992 18.2186 66.8122 18.011 67.1762 17.6048C67.5403 17.2 67.8224 16.2852 68.0256 14.8603C68.1864 13.6205 68.2137 12.8291 68.1045 12.483C67.9953 12.1339 67.6859 11.9594 67.1762 11.9594C66.3343 11.9594 65.2801 12.4875 64.0151 13.5423Z"
            fill="#0254F2"/>
        <path
            d="M79.2025 9.34741C81.1957 9.34741 82.5851 9.79579 83.3709 10.6895C84.1551 11.5803 84.4023 13.0578 84.1111 15.1192C83.832 17.1218 83.2313 18.5347 82.3091 19.3562C81.3868 20.1792 79.9519 20.59 78.0073 20.59C76.0293 20.59 74.6368 20.1431 73.8328 19.2479C73.0274 18.3541 72.771 16.8916 73.0623 14.8604C73.349 12.8427 73.9496 11.4208 74.8643 10.5932C75.782 9.76269 77.2276 9.34741 79.2025 9.34741ZM79.1843 11.7488C78.2985 11.7488 77.6614 11.9715 77.2731 12.4169C76.8878 12.8622 76.6026 13.7635 76.4176 15.1192C76.2431 16.359 76.2629 17.182 76.4783 17.5868C76.6967 17.9885 77.2276 18.1886 78.074 18.1886C78.9432 18.1886 79.5666 17.9629 79.9428 17.5085C80.322 17.0556 80.6072 16.1724 80.7983 14.8604C80.9833 13.6206 80.9636 12.793 80.7376 12.3747C80.5146 11.9579 79.9974 11.7488 79.1843 11.7488Z"
            fill="#0254F2"/>
        <path
            d="M91.9719 9.34741C93.965 9.34741 95.3545 9.79579 96.1402 10.6895C96.9244 11.5803 97.1717 13.0578 96.8804 15.1192C96.6013 17.1218 96.0007 18.5347 95.0784 19.3562C94.1562 20.1792 92.7212 20.59 90.7766 20.59C88.7986 20.59 87.4061 20.1431 86.6022 19.2479C85.7967 18.3541 85.5404 16.8916 85.8316 14.8604C86.1183 12.8427 86.719 11.4208 87.6336 10.5932C88.5513 9.76269 89.9969 9.34741 91.9719 9.34741ZM91.9537 11.7488C91.0678 11.7488 90.4307 11.9715 90.0424 12.4169C89.6571 12.8622 89.372 13.7635 89.1869 15.1192C89.0125 16.359 89.0322 17.182 89.2476 17.5868C89.466 17.9885 89.9969 18.1886 90.8433 18.1886C91.7125 18.1886 92.3359 17.9629 92.7121 17.5085C93.0913 17.0556 93.3765 16.1724 93.5676 14.8604C93.7527 13.6206 93.7329 12.793 93.5069 12.3747C93.284 11.9579 92.7667 11.7488 91.9537 11.7488Z"
            fill="#0254F2"/>
        <path
            d="M103.722 16.4794L101.125 15.4201C100.254 15.0756 99.6445 14.6362 99.2926 14.1021C98.9437 13.5694 98.8209 12.8984 98.9225 12.0919C99.0393 11.1139 99.4716 10.4067 100.221 9.97337C100.969 9.54003 102.141 9.3324 103.74 9.34744C105.718 9.37603 107.374 9.52047 108.709 9.78078L108.406 11.809C105.615 11.7699 104.197 11.7488 104.153 11.7488C103.311 11.7488 102.753 11.7819 102.478 11.8451C102.202 11.9098 102.043 12.0648 101.999 12.3086C101.97 12.5538 102.038 12.7389 102.205 12.8623C102.37 12.9826 102.693 13.1376 103.176 13.3257L105.809 14.3609C106.698 14.7069 107.323 15.1252 107.684 15.6187C108.048 16.1092 108.178 16.7623 108.078 17.5808C107.993 18.2157 107.823 18.7348 107.569 19.1396C107.314 19.5458 106.945 19.8483 106.464 20.0484C105.987 20.25 105.471 20.3809 104.917 20.4456C104.367 20.5133 103.663 20.5479 102.806 20.5479C101.498 20.5479 99.9206 20.417 98.0731 20.1567L98.3825 18.1044C98.9043 18.133 100.045 18.1465 101.805 18.1465C103.05 18.1465 103.877 18.1044 104.286 18.0201C104.694 17.9329 104.926 17.7433 104.984 17.4544C105.011 17.2377 104.957 17.0797 104.82 16.9789C104.682 16.8796 104.409 16.7487 104.001 16.5877C103.887 16.5441 103.795 16.508 103.722 16.4794Z"
            fill="#0254F2"/>
        <path
            d="M115.092 11.7909L114.376 16.8885C114.318 17.2948 114.351 17.5656 114.473 17.701C114.597 17.838 114.876 17.9057 115.31 17.9057H116.748L116.906 20.1566C116.132 20.4289 115.13 20.5659 113.896 20.5659C112.877 20.5659 112.12 20.289 111.627 19.7353C111.133 19.1816 110.96 18.4142 111.105 17.4302L111.937 11.7909H109.995L110.256 9.7566L112.24 9.64827L112.677 6.66309H115.838L115.419 9.64827H118.386L118.083 11.7909H115.092Z"
            fill="#0254F2"/>
    </svg>
    )
}

export default LogoLarge;