import styles from "./OverlayModal.module.css";
import React from "react";

const OverlayModal = ({children, isVisible, title, onClose}) => {
    if (!isVisible) return null;

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                {title &&
                    <header className={styles.header}>
                        <span>{title}</span>
                        <button onClick={onClose}>&times;</button>
                    </header>}
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default OverlayModal;