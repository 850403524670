import React, { useState, useEffect } from 'react';
import styles from './infobanner.module.css'; // Import styles as a module

const InfoBanner = ({ message, visible, setBannerData }) => {

    useEffect(() => {
        // Set a timer to hide the banner after 3 seconds
        const timer = setTimeout(() => {
            setBannerData({visible: false, message: ''});
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`${styles.infoBanner} ${visible ? styles.show : ''}`}>
            {message.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
        </div>
    );
};

export default InfoBanner;
