import React from 'react';
import styles from './YesOrNoOverlayModal.module.css';
import buttonStyles from '../Button_v2/Button_V2.module.css';
import OverlayModal from './OverlayModal';
import Button from "../Button_v2/Button"

const YesOrNoOverlayModal = ({children, isVisible, onClose, onAccept, onCancel, title}) => {
    return (
        <OverlayModal title={title} onClose={onClose} isVisible={isVisible}>
            <p>{children}</p>
            <div className={styles.buttonContainer}>
                <Button onClick={onAccept} cssClass={buttonStyles.btn_danger}>Accept</Button>
                <Button onClick={onAccept} cssClass={buttonStyles.btn_primary}>Cancel</Button>
            </div>
        </OverlayModal>
    );
};

export default YesOrNoOverlayModal;
