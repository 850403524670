import React, { useState, useEffect } from 'react';
import { useMemberstackModal, useMemberstack } from '@memberstack/react';
import styles from './authpage.module.css'; // Assuming you have a corresponding CSS module
import LearnboostLogo from '../LearnboostLogoLarge.png';
import backgroundvideo from '../background.mp4';
import ClipLoader from "react-spinners/ClipLoader";

import posthog from 'posthog-js'

const AuthPage = ({ onAuthSuccess, setUserId, setProfilePicture, isPaidUser, setIsPaidUser, plan, setPlan, setLanguage }) => {
  const { openModal, hideModal } = useMemberstackModal();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const memberstack = useMemberstack();

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const {data} = await memberstack.getCurrentMember();
        setLoginParameters(data)
        setIsAuthenticated(true);
        onAuthSuccess();
        // Additional logic for handling the member data goes here
      } catch (error) {
        //console.error("Error fetching member:", error);
        setIsAuthenticated(false);
        // Handle the error scenario
      }
    };
  
    fetchMember();
  }, [memberstack]); // Include memberstack in the dependency array if it's a state or prop
  


  const setLoginParameters = (member) => {
    //console.log(member.id)
    setUserId(member.id)
    //console.log(member.profileImage)
    setProfilePicture(member.profileImage)
    //console.log(member.planConnections);
    if (member.customFields.default_language && (member.customFields.default_language === "English" || member.customFields.default_language === "German")) {
      setLanguage(member.customFields.default_language);
      console.log("Language", member.customFields.default_language);
    }
    
    try {
      const subscriptionPlan = member.planConnections.find(planConnection => 
        (planConnection.active === true || planConnection.status === "TRIALING") && planConnection.type === "SUBSCRIPTION"
      );

      let userPlan = "free";
      let localIsPaidUser = false;

      if (subscriptionPlan) {
        const payment = subscriptionPlan.payment;
        const plan = subscriptionPlan.payment.priceId;

        //console.log("Selected Plan Connection:", subscriptionPlan);
        //console.log("payment", payment);
        //console.log("plan", plan);

        setIsPaidUser(payment.status === "PAID" ? true : false);
        localIsPaidUser = true;

        switch (true) {
          case plan.includes("unlimited-semester"):
            setPlan("🎉 Unlimited");
            userPlan = "unlimited-semester";
            break;
          case plan.includes("unlimited-flex"):
            setPlan("🎉 Unlimited");
            userPlan = "unlimited-flex";
            break;
          default:
            setPlan("Upgrade Plan");
        }
      } else {
        setIsPaidUser(false);
        setPlan("Upgrade Plan");
      }

      posthog.identify(member.id, {
        email: member.auth.email,
        plan: userPlan,
        isPaidUser: localIsPaidUser
      });

    } catch {
      setIsPaidUser(false);
      setPlan("Upgrade Plan");
    }
  }

  const handleAuth = async (type) => {
    try {
      const { data, type: responseType } = await openModal({ type: type });
      //console.log(data)
      //console.log(responseType)
      if (responseType === "LOGIN") {

        setLoginParameters(data.member);

        setIsAuthenticated(true);
        onAuthSuccess();

        hideModal();
      }
      if (responseType === "SIGNUP") {
        
        setLoginParameters(data.member);

        setIsAuthenticated(true);
        onAuthSuccess();

        hideModal();
      }
    } catch (error) {
      console.error("Error in Memberstack modal:", error);
    }
  };
  
  if (isAuthenticated === null) {
    return (
      <div className={styles.loadingContainer}>
        <ClipLoader color='#256EFF'/>
      </div>
    )
  }

  return (
    <div className={styles.container}>
    <div className={styles.decorationContainer}>
      <div className={styles['learnboost-logo']}>
        Learn<i>boost</i>
      </div>
      <div className={styles['slogan']}>
        <p>Welcome to the</p>
        <p>Future of <i>Learning.</i></p> 
      </div>
    </div>
      <div className={styles.login}>
          <h2>Get started</h2>
          <div className={styles['buttonContainer']}>
            <button className={styles['loginbutton']} onClick={() => handleAuth("LOGIN")}>Login</button>
            <button className={styles['signupbutton']} onClick={() => handleAuth("SIGNUP")}>Sign Up</button>
          </div>

          <a className={styles['websiteLink']} href='https://www.learnboost.tech/'>
              Go to website
          </a>
        </div>
    </div>
  );
};

export default AuthPage;
