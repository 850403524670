const HistoryIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_45_426" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                  height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_45_426)">
                <path
                    d="M11.9806 20.5C9.97043 20.5 8.19868 19.8865 6.66534 18.6595C5.13201 17.4327 4.14926 15.8628 3.71709 13.95C3.66326 13.7513 3.69568 13.5687 3.81434 13.402C3.93284 13.2353 4.10076 13.1385 4.31809 13.1115C4.52259 13.0847 4.70593 13.1251 4.86809 13.2327C5.03026 13.3404 5.14343 13.4988 5.20759 13.7078C5.58826 15.2526 6.40843 16.5208 7.6681 17.5125C8.92776 18.5042 10.3653 19 11.9806 19C13.9306 19 15.5848 18.3208 16.9431 16.9625C18.3014 15.6042 18.9806 13.95 18.9806 12C18.9806 10.05 18.3014 8.39583 16.9431 7.0375C15.5848 5.67917 13.9306 5 11.9806 5C10.8883 5 9.86459 5.24267 8.9096 5.728C7.95443 6.21317 7.13193 6.88075 6.44209 7.73075H8.30759C8.52009 7.73075 8.69818 7.80267 8.84184 7.9465C8.98568 8.09033 9.05759 8.2685 9.05759 8.481C9.05759 8.69367 8.98568 8.87175 8.84184 9.01525C8.69818 9.15892 8.52009 9.23075 8.30759 9.23075H4.88459C4.62843 9.23075 4.41376 9.14408 4.24059 8.97075C4.06726 8.79758 3.98059 8.58292 3.98059 8.32675V4.90375C3.98059 4.69125 4.05251 4.51317 4.19634 4.3695C4.34018 4.22567 4.51843 4.15375 4.73109 4.15375C4.94359 4.15375 5.12168 4.22567 5.26534 4.3695C5.40884 4.51317 5.48059 4.69125 5.48059 4.90375V6.523C6.29209 5.56533 7.26393 4.82208 8.39609 4.29325C9.52809 3.76442 10.7229 3.5 11.9806 3.5C13.1606 3.5 14.2658 3.72308 15.2963 4.16925C16.3268 4.61542 17.2254 5.22183 17.9921 5.9885C18.7588 6.75517 19.3652 7.65367 19.8113 8.684C20.2575 9.7145 20.4806 10.8197 20.4806 11.9995C20.4806 13.1793 20.2575 14.2846 19.8113 15.3152C19.3652 16.3461 18.7588 17.2448 17.9921 18.0115C17.2254 18.7782 16.3268 19.3846 15.2963 19.8307C14.2658 20.2769 13.1606 20.5 11.9806 20.5Z"
                    fill="black"/>
            </g>
        </svg>
    )
}

export default HistoryIcon;