import React, { useState } from 'react';
import styles from './flashcardbutton.module.css';

const ChevronDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24" fill="#FFFFFF">
    <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
  </svg>
);

const StarIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFFF" className="w-6 h-6">
        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
      </svg>     
    );
};



function FlashCardButton({document_id, handleFlashcardCreation, appLanguage, showInfoBanner}) {
  const [showDropdownConfig, setShowDropdownConfig] = useState(false);
  const [showDropdownPages, setShowDropdownPages] = useState(false);
  const [showDropdownLanguage, setShowDropdownLanguage] = useState(false);
  const [config, setConfig] = useState("Q/A - Pairs");
  const [pages, setPages] = useState("All Pages");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");

  const modes = {
    "Q/A - Pairs": "DETAILED_IN_BULLET_POINTS",
    "More coming...": ""
  }

  const languages = {
    "DE": "German",
    "EN": "English"
  }
  
  const languageKey = Object.keys(languages).find(key => languages[key] === appLanguage);
  const [language, setLanguage] = useState(languageKey || "English"); 

  const [showDropdownImages, setShowDropdownImages] = useState(false);
  const [includeImages, setIncludeImages] = useState(true);

  const toggleIncludeImages = (e) => {
    setIncludeImages(e);
  };


  

  const getPageInterval = () => {
    if(firstPage === "" && lastPage === "" || firstPage === "1" && lastPage === ""){
      return null
    }
    if(firstPage === "" && lastPage !== ""){
      return [1, lastPage];
    }
    if(firstPage !== "" && lastPage === ""){
      return [firstPage, "MAX"];
    }

      return [firstPage, lastPage];
  }

  const generate = () => {
    if (lastPage !== "" && firstPage !== "" && parseInt(lastPage) < parseInt(firstPage)) {
      console.log(lastPage)
      console.log(firstPage)
      showInfoBanner("Invalid range for pages: Please choose the first page number to be smaller than the last");
      return;
    }
    //console.log(languages[language])
    //console.log(modes[config])
    //console.log(getPageInterval(pages))
    handleFlashcardCreation(document_id, languages[language], modes[config], getPageInterval(pages), includeImages);
  }

  const updatePages = (firstPage, lastPage) => {
    if(firstPage === "" && lastPage === "" || firstPage === "1" && lastPage === ""){
      setPages("All Pages");
      return;
    }
    if(firstPage === "" && lastPage !== ""){
      setPages(`Until Page ${lastPage}`);
      return;
    }
    if(firstPage !== "" && lastPage === ""){
      setPages(`After Page ${firstPage}`);
      return;
    }

      setPages(`${firstPage} to ${lastPage}`);
  }

  const handleFirstPageChange = (event) => {
    const value = event.target.value;
    if(value === "0"){
      showInfoBanner("Please enter a positive number.")
      return;
    }
    if (!isNaN(value)) {
      setFirstPage(value);
      updatePages(value, lastPage);
    } else {
      console.log("Please input numbers only for the page number.");
    }
  };

  const handleLastPageChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setLastPage(value);
      updatePages(firstPage, value);
    } else {
      console.log("Please input numbers only for the page number.");
    }
  };
  
    return (
      <div className={styles.uploadContainer}>
        <div className={styles.iconCircle}>
        <span className={styles.uploadIcon}>
            <StarIcon/>
        </span>
        </div>
        <h3 className={styles.uploadTextSubtitle}>
          Generate a complete set of
          <br></br>
          Flashcards with one click
        </h3>
        <div className={styles.uploadArea}>
        <div className={styles.generateButton}>
          <button className={styles.generateBtn} onClick={generate}>
             Generate now!
          </button>
        </div>
          <div className={styles.buttons}>
          <div className={styles.dropdown}>
            <button className={styles.uploadBtn} onClick={() => setShowDropdownConfig(!showDropdownConfig)}>
              {config}
              <ChevronDownIcon/>
            </button>
            {showDropdownConfig && (
              <div className={styles.dropdownContent}>
                {Object.entries(modes).map(([label, item], index) => (
                  <button
                    key={index}
                    className={styles.uploadBtn}
                    onClick={() => {
                      if(item === "")
                      {
                        return
                      } 
                      setConfig(label); 
                      setShowDropdownConfig(false); 
                    }}
                  >
                    {label}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className={styles.dropdown}>
            <button className={styles.uploadBtn} onClick={() => setShowDropdownPages(!showDropdownPages)}>
              {pages}
              <ChevronDownIcon/>
            </button>
            {showDropdownPages && (
              <div className={styles.dropdownContentPages}>
                <input
                  className={styles.pageInput}
                  value={firstPage}
                  onChange={handleFirstPageChange}
                  placeholder="1"
                />
                to
                <input
                  className={styles.pageInput}
                  value={lastPage}
                  onChange={handleLastPageChange}
                  placeholder="100"
                />
              </div>
            )}
          </div>
          <div className={styles.dropdown}>
            <button className={styles.uploadBtn} onClick={() => setShowDropdownLanguage(!showDropdownLanguage)}>
              {language} <ChevronDownIcon />
            </button>
            {showDropdownLanguage && (
              <div className={styles.dropdownContent}>
                {Object.entries(languages).map(([code, name], index) => (
                  <button
                    key={index}
                    className={styles.uploadBtn}
                    onClick={() => { setLanguage(code); setShowDropdownLanguage(false); }}
                  >
                    {code}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.generateButton}>
            <button className={styles.imageBtn} onClick={() => setShowDropdownImages(!showDropdownImages)}>
              {includeImages ? "Images On" : "Images Off"} <ChevronDownIcon></ChevronDownIcon>
            </button>
        </div>
        <div className={styles.dropdownImages}>
            {showDropdownImages && (
              <div className={styles.dropdownContent}>
                <button
                  className={styles.generateBtn}
                  onClick={() => { toggleIncludeImages(true); setShowDropdownImages(false); }}
                >
                  Images On
                </button>
                <button
                  className={styles.generateBtn}
                  onClick={() => { toggleIncludeImages(false); setShowDropdownImages(false); }}
                >
                  Images Off
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
}


export default FlashCardButton;
