import React, { useEffect, useMemo, useRef, useState } from "react";

import {
    Button,
    createStore,
    Plugin,
    PluginFunctions,
    Position,
    SpecialZoomLevel,
    Tooltip,
    Viewer,
    Worker,
} from "@react-pdf-viewer/core";
import { fullScreenPlugin, RenderEnterFullScreenProps } from "@react-pdf-viewer/full-screen";
import { highlightPlugin, MessageIcon, Trigger } from "@react-pdf-viewer/highlight";
import {
    pageNavigationPlugin,
    RenderCurrentPageLabelProps,
} from "@react-pdf-viewer/page-navigation";

import styles from "./reactpdfviewer.module.css";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import PdfIcon from "./PdfIcon";

const FullscreenIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#718096"
        className="w-6 h-6">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
        />
    </svg>
);

const ZoomInIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#718096"
        className="w-6 h-6">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"
        />
    </svg>
);

const ZoomOutIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#718096"
        className="w-6 h-6">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6"
        />
    </svg>
);

const CustomZoomPlugin = () => {
    const store = useMemo(() => createStore({}), []);

    return {
        install: (pluginFunctions) => {
            store.update("zoom", pluginFunctions.zoom);
        },
        zoomTo: (scale) => {
            const zoom = store.get("zoom");
            if (zoom) {
                // Zoom to that scale
                zoom(scale);
            }
        },
    };
};

const JumpToPagePlugin = () => {
    const store = React.useMemo(() => createStore({}), []);

    return {
        install: (pluginFunctions) => {
            store.update("jumpToPage", pluginFunctions.jumpToPage);
        },
        jumpToPage: (pageIndex) => {
            const fn = store.get("jumpToPage");
            if (fn) {
                fn(pageIndex);
            }
        },
    };
};

const ReactPDFViewer = ({ pdfUrl, currentAreas, currentPageReference }) => {
    const [numPages, setNumPages] = useState(0);
    const [pdfDoc, setPdfDoc] = useState(null);
    const [scale, setScale] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [targetPage, setTargetPage] = useState("1");
    const viewerRef = useRef(null);
    const customZoomPluginInstance = CustomZoomPlugin();
    const { zoomTo } = customZoomPluginInstance;
    const [areas, setAreas] = useState([]);
    useEffect(() => {
        jumpToPage(parseInt(currentPageReference) - 1);
    }, [currentPageReference]);

    useEffect(() => {
        setAreas(currentAreas);
    }, [currentAreas]);

    const handleDocumentLoadSuccess = (props) => {
        setNumPages(props.doc._pdfInfo.numPages); // Update state with the total number of pages
    };

    const jumpToPagePluginInstance = JumpToPagePlugin();
    const { jumpToPage } = jumpToPagePluginInstance;

    const fullScreenPluginInstance = fullScreenPlugin({
        renderExitFullScreenButton: (props) => (
            <div className={styles.exitbutton} onClick={props.onClick}>
                Exit
            </div>
        ),
    });

    const { EnterFullScreen } = fullScreenPluginInstance;

    const pageNavigationPluginInstance = pageNavigationPlugin();

    const renderHighlights = (props) => {
        //console.log(areas)
        return (
            <div>
                {areas
                    .filter((area) => area.pageIndex === props.pageIndex)
                    .map((area, idx) => (
                        <div
                            key={idx}
                            className="highlight-area"
                            style={Object.assign(
                                {},
                                {
                                    background: "yellow",
                                    opacity: 0.2,
                                },
                                props.getCssProperties(area, props.rotation)
                            )}
                        />
                    ))}
            </div>
        );
    };

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.None,
    });

    useEffect(() => {
        setTargetPage(String(currentPage));
    }, [currentPage]);

    const handlePageChange = (e) => {
        setCurrentPage(e.currentPage + 1);
    };

    const handlePageJump = (targetPage) => {
        const pageNumber = parseInt(targetPage - 1, 10);
        jumpToPage(pageNumber);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handlePageJump(targetPage);
        }
    };

    const handleZoomIn = (prevScale) => {
        const newScale = Math.min(2.5, prevScale + 0.1);
        setScale(newScale);
        return newScale;
    };

    const handleZoomOut = (prevScale) => {
        const newScale = Math.max(0.3, prevScale - 0.1);
        setScale(newScale);
        return newScale;
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.pageNavContainer}>
                    <input
                        className={styles.form}
                        type="text"
                        value={targetPage}
                        onChange={(e) => setTargetPage(e.target.value)}
                        onBlur={() => {
                            handlePageJump(targetPage);
                        }}
                        onKeyPress={handleKeyPress}
                    />
                    <span className={styles.pageNumber}>{numPages}</span>
                    <div className={styles["group2"]}>
                        <button
                            className={styles.zoomIn}
                            onClick={() => zoomTo(handleZoomIn(scale))}>
                            <PdfIcon name="zoom-in" />
                        </button>
                        <button
                            className={styles.zoomOut}
                            onClick={() => zoomTo(handleZoomOut(scale))}>
                            <PdfIcon name="zoom-out" />
                        </button>
                        <EnterFullScreen>
                            {(props) => (
                                <button className={styles.fullscreen} onClick={props.onClick}>
                                    <PdfIcon name="fullscreen" />
                                </button>
                            )}
                        </EnterFullScreen>
                    </div>
                </div>
                <div className={styles.pageViewContainer}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div className={styles.viewer} onClick={() => setAreas([])}>
                            <Viewer
                                fileUrl={pdfUrl}
                                onDocumentLoad={handleDocumentLoadSuccess}
                                onPageChange={handlePageChange}
                                plugins={[
                                    customZoomPluginInstance,
                                    fullScreenPluginInstance,
                                    jumpToPagePluginInstance,
                                    pageNavigationPluginInstance,
                                    highlightPluginInstance,
                                ]}
                            />
                        </div>
                    </Worker>
                </div>
            </div>
        </>
    );
};

export default ReactPDFViewer;
