const jokes = [{'punchline': 'Checkmatey.',
'setup': 'What did one pirate say to the other when he beat him at chess?'},
{'punchline': 'I left my food in the oven for too long.',
'setup': 'I burned 2000 calories today'},
{'punchline': 'I had to calm him down by saying “Don’t worry, this is just a drill!”',
'setup': 'I startled my next-door neighbor with my new electric power tool.'},
{'punchline': 'My doctor told me to stop going to those places.',
'setup': 'I broke my arm in two places.'},
{'punchline': 'It was just the same old grind over and over.',
'setup': 'I quit my job at the coffee shop the other day.'},
{'punchline': 'it’s a total rip-off.',
'setup': 'I never buy anything that has Velcro with it...'},
{'punchline': 'it was soda pressing.',
'setup': 'I used to work at a soft drink can crushing company...'},
{'punchline': 'Then it hit me.',
'setup': 'I wondered why the frisbee kept on getting bigger.'},
{'punchline': 'but I forgot the punch line.',
'setup': 'I was going to tell you a fighting joke...'},
{'punchline': 'The shovel.',
'setup': 'What is the most groundbreaking invention of all time?'},
{'punchline': 'I can’t wait.',
'setup': 'I’m starting my new job at a restaurant next week.'},
{'punchline': 'they told me I have to have cookies disabled.',
'setup': 'I visited a weight loss website...'},
{'punchline': 'He pasta way.',
'setup': 'Did you hear about the famous Italian chef that recently died?'},
{'punchline': 'no strings attached.', 'setup': 'Broken guitar for sale'},
{'punchline': 'it’s too hard watching your life’s work go down the drain.',
'setup': 'I could never be a plumber'},
{'punchline': 'but I think I may have grater problems than that.',
'setup': 'I cut my finger slicing cheese the other day...'},
{'punchline': 'Tooth-hurty.',
'setup': 'What time did you go to the dentist yesterday?'},
{'punchline': 'Neptunes.',
'setup': 'What kind of music do astronauts listen to?'},
{'punchline': 'You will be mist.', 'setup': 'Rest in peace, boiled water.'},
{'punchline': '50 Cent, featuring Nickelback.',
'setup': 'What is the only concert in the world that costs 45 cents?'},
{'punchline': 'it’s a father figure.', 'setup': 'It’s not a dad bod'},
{'punchline': 'It’s enough to make a mango crazy.',
'setup': 'My wife recently went on a tropical food diet and now our house is full of this stuff.'},
{'punchline': 'Subordinate clauses.',
'setup': 'What do you call Santa’s little helpers?'},
{'punchline': 'Sorry, I’m still working on it.',
'setup': 'Want to hear a construction joke?'},
{'punchline': 'One is extremely big and heavy, and the other is a little lighter.',
'setup': 'What’s the difference between a hippo and a zippo?'},
{'punchline': 'I should have cooked it on aloha temperature.',
'setup': 'I burnt my Hawaiian pizza today in the oven,'},
{'punchline': 'but if you want to be cremated then you have to urn it.',
'setup': 'Anyone can be buried when they die'},
{'punchline': 'From the second-hand store.',
'setup': 'Where did Captain Hook get his hook?'},
{'punchline': 'solo that they can’t hear me.',
'setup': 'I am such a good singer that people always ask me to sing solo'},
{'punchline': 'tenor twelve miles away.',
'setup': 'I am such a good singer that people ask me to sing tenor'},
{'punchline': 'That’s just how I roll.',
'setup': 'Occasionally to relax I just like to tuck my knees into my chest and lean forward.'},
{'punchline': 'They barley knew each other.',
'setup': 'What did the glass of wine say to the glass of beer? Nothing.'},
{'punchline': 'They are always up to something.',
'setup': 'I’ve never trusted stairs.'},
{'punchline': 'She got sick of all the drama.',
'setup': 'Why did Shakespeare’s wife leave him?'},
{'punchline': 'I have no words to describe how mad I am.',
'setup': 'I just bought a dictionary but all of the pages are blank.'},
{'punchline': 'you’re going to have to apply daily.',
'setup': 'If you want to get a job at the moisturizer factory...'},
{'punchline': 'It’s probably because I don’t have 2020 vision.',
'setup': 'I don’t know what’s going to happen next year.'},
{'punchline': 'Urine for a treat.',
'setup': 'Want to hear a joke about going to the bathroom?'},
{'punchline': 'Then it just clicked.',
'setup': 'I couldn’t figure out how to use the seat belt.'},
{'punchline': 'turns out it was just spam.',
'setup': 'I got an email the other day teaching me how to read maps backwards'},
{'punchline': "It's impossible to put down!",
'setup': "I'm reading a book about anti-gravity."},
{'punchline': 'European.',
'setup': "You're American when you go into the bathroom, and you're American when you come out, but do you know what you are while you're in there?"},
{'punchline': 'They were cooked in Greece.',
'setup': "Did you know the first French fries weren't actually cooked in France?"},
{'punchline': "it's tearable.",
'setup': 'Want to hear a joke about a piece of paper? Never mind...'},
{'punchline': 'It was the best dam show I ever saw!',
'setup': 'I just watched a documentary about beavers.'},
{'punchline': 'An iWitness?',
'setup': 'If you see a robbery at an Apple Store what re you?'},
{'punchline': 'I got so excited I wet my plants!', 'setup': 'Spring is here!'},
{'punchline': '1forrest1', 'setup': 'What’s Forrest Gump’s password?'},
{'punchline': 'Because he was a little horse!',
'setup': 'Why did the Clydesdale give the pony a glass of water?'},
{'punchline': 'DAD: "No, just leave it in the carton!’”',
'setup': 'CASHIER: "Would you like the milk in a bag, sir?"'},
{'punchline': 'They say he made a mint.',
'setup': 'Did you hear about the guy who invented Lifesavers?'},
{'punchline': "I don't know what he laced them with, but I was tripping all day!",
'setup': 'I bought some shoes from a drug dealer.'},
{'punchline': 'Because if they had four, they would be chicken sedans!',
'setup': 'Why do chicken coops only have two doors?'},
{'punchline': 'Put a little boogie in it!',
'setup': 'How do you make a Kleenex dance?'},
{'punchline': '"Is the bar tender here?"',
'setup': 'A termite walks into a bar and asks'},
{'punchline': "He couldn't see himself doing it.",
'setup': 'Why did the invisible man turn down the job offer?'},
{'punchline': 'but I got the sack because I took a couple of days off.',
'setup': 'I used to have a job at a calendar factory'},
{'punchline': 'She says, "No, first a Gibson! Then a Fender!”',
'setup': 'A woman is on trial for beating her husband to death with his guitar collection. Judge says, "First offender?"'},
{'punchline': 'You boil the hell out of it.',
'setup': 'How do you make holy water?'},
{'punchline': 'I woke up exhausted!',
'setup': 'I had a dream that I was a muffler last night.'},
{'punchline': 'It was in tents!',
'setup': 'Did you hear about the circus fire?'},
{'punchline': 'They make up everything!', 'setup': "Don't trust atoms."},
{'punchline': 'Ten-tickles.',
'setup': 'How many tickles does it take to make an octopus laugh?'},
{'punchline': 'I don’t know why.',
'setup': 'I’m only familiar with 25 letters in the English language.'},
{'punchline': 'Because he is OUT-STANDING in his field!',
'setup': 'Why did the cow in the pasture get promoted at work?'},
{'punchline': 'Cell phones.',
'setup': 'What do prisoners use to call each other?'},
{'punchline': 'It was two tired.',
'setup': "Why couldn't the bike standup by itself?"},
{'punchline': 'Sir Cumference.',
'setup': 'Who was the fattest knight at King Arthur’s round table?'},
{'punchline': 'It’s because the cows weren’t getting a square meal.',
'setup': 'Did you see they made round bails of hay illegal in Wisconsin?'},
{'punchline': 'A trumpet.',
'setup': 'You know what the loudest pet you can get is?'},
{'punchline': 'Frostbite.',
'setup': 'What do you get when you cross a snowman with a vampire?'},
{'punchline': 'No idea!', 'setup': 'What do you call a deer with no eyes?'},
{'punchline': 'No, but April May!', 'setup': 'Can February March?'},
{'punchline': 'Provolone.', 'setup': 'What do you call a lonely cheese?'},
{'punchline': 'Because the pee is silent.',
'setup': "Why can't you hear a pterodactyl go to the bathroom?"},
{'punchline': 'Bison.',
'setup': 'What did the buffalo say to his son when he dropped him off at school?'},
{'punchline': 'Nobody knows.',
'setup': 'What do you call someone with no body and no nose?'},
{'punchline': "They're good but they haven't got a gig yet.",
'setup': 'You heard of that new band 1023MB?'},
{'punchline': "Because he's shellfish.",
'setup': 'Why did the crab never share?'},
{'punchline': 'Act like a nut.',
'setup': 'How do you get a squirrel to like you?'},
{'punchline': "They'd crack each other up.",
'setup': "Why don't eggs tell jokes?"},
{'punchline': 'Because then it would be a foot.',
'setup': "Why can't a nose be 12 inches long?"},
{'punchline': "Well, I'm not going to spread it!",
'setup': 'Did you hear the rumor about butter?'},
{'punchline': 'It was pointless.',
'setup': 'I made a pencil with two erasers.'},
{'punchline': 'but then it grew on me.',
'setup': 'I used to hate facial hair...'},
{'punchline': 'it was just gathering dust!',
'setup': 'I decided to sell my vacuum cleaner—'},
{'punchline': "and I've never looked back since.",
'setup': 'I had a neck brace fitted years ago'},
{'punchline': 'but I feel like I was just born with mine.',
'setup': 'You know, people say they pick their nose,'},
{'punchline': 'An irrelephant.',
'setup': "What do you call an elephant that doesn't matter?"},
{'punchline': 'Spoiled milk.',
'setup': 'What do you get from a pampered cow?'},
{'punchline': "It's a faux pa.",
'setup': "It's inappropriate to make a 'dad joke' if you're not a dad."},
{'punchline': 'Sue ya later!', 'setup': 'How do lawyers say goodbye?'},
{'punchline': "Never mind—it's tearable.",
'setup': 'Wanna hear a joke about paper?'},
{'punchline': 'Live stream.',
'setup': "What's the best way to watch a fly fishing tournament?"},
{'punchline': "but it's a little cheesy.",
'setup': 'I could tell a joke about pizza,'},
{'punchline': 'When it becomes apparent.',
'setup': 'When does a joke become a dad joke?'},
{'punchline': 'The space bar.',
'setup': 'What’s an astronaut’s favorite part of a computer?'},
{'punchline': 'That she was a little boulder.',
'setup': 'What did the shy pebble wish for?'},
{'punchline': "I'm just going to ask them where they are going and meet up with them later.",
'setup': "I'm tired of following my dreams."},
{'punchline': "He's all right now.",
'setup': 'Did you hear about the guy whose whole left side was cut off?'},
{'punchline': 'Because he had no guts.',
'setup': 'Why didn’t the skeleton cross the road?'},
{'punchline': "I'm a cashew!",
'setup': 'What did one nut say as he chased another nut?'},
{'punchline': "you've seen a mall.",
'setup': "Chances are if you' ve seen one shopping center..."},
{'punchline': 'but it was a whisk I was willing to take.',
'setup': "I knew I shouldn't steal a mixer from work..."},
{'punchline': 'Because all of the fans left.',
'setup': 'How come the stadium got hot after the game?'},
{'punchline': 'Because of all the knights.',
'setup': 'Why was it called the dark ages?'},
{'punchline': 'Because it saw the salad dressing.',
'setup': 'Why did the tomato blush?'},
{'punchline': 'She was a roman catholic.',
'setup': 'Did you hear the joke about the wandering nun?'},
{'punchline': 'A spelling bee.',
'setup': 'What creature is smarter than a talking parrot?'},
{'punchline': 'garden fences.',
'setup': "I'll tell you what often gets over looked..."},
{'punchline': 'To get to the other slide.',
'setup': 'Why did the kid cross the playground?'},
{'punchline': "Because it's too far to walk.",
'setup': 'Why do birds fly south for the winter?'},
{'punchline': 'I want to hold your hand, hand, hand, hand...',
'setup': "What is a centipedes's favorite Beatle song?"},
{'punchline': 'The second time let me down.',
'setup': 'My first time using an elevator was an uplifting experience.'},
{'punchline': "I'd have to change my name.", 'setup': 'To be Frank...'},
{'punchline': 'woke up in the fireplace.',
'setup': 'Slept like a log last night …'},
{'punchline': "Because it's a little meteor.",
'setup': 'Why does a Moon-rock taste better than an Earth-rock?'},
{'punchline': 'A Brazilian',
'setup': 'How many South Americans does it take to change a lightbulb?'},
{'punchline': "They're always up to something.",
'setup': "I don't trust stairs."},
{'punchline': 'He charged one and let the other one off.',
'setup': 'A police officer caught two kids playing with a firework and a car battery.'},
{'punchline': "I don't know and I don't care.",
'setup': 'What is the difference between ignorance and apathy?'},
{'punchline': 'It was Everlong...',
'setup': 'I went to a Foo Fighters Concert once...'},
{'punchline': "They say it's a nice light snack.",
'setup': 'Some people eat light bulbs.'},
{'punchline': 'Sore arms.',
'setup': 'What do you get hanging from Apple trees?'},
{'punchline': 'Luckily I was the one facing the TV.',
'setup': 'Last night me and my girlfriend watched three DVDs back to back.'},
{'punchline': "I can't wait to see how it turns out.",
'setup': 'I got a reversible jacket for Christmas,'},
{'punchline': 'Lil Caesars',
'setup': 'What did Romans use to cut pizza before the rolling cutter was invented?'},
{'punchline': 'He was caught in a trap..',
'setup': "My pet mouse 'Elvis' died last night."},
{'punchline': 'They are always negative.',
'setup': 'Never take advice from electrons.'},
{'punchline': 'Because they are made to concentrate.',
'setup': 'Why are oranges the smartest fruit?'},
{'punchline': "It's been nice gnawing you.",
'setup': 'What did the beaver say to the tree?'},
{'punchline': 'You use a pumpkin patch.',
'setup': 'How do you fix a damaged jack-o-lantern?'},
{'punchline': 'I’ll ketch up',
'setup': 'What did the late tomato say to the early tomato?'},
{'punchline': 'when it gets bad, I take something for it.',
'setup': 'I have kleptomania...'},
{'punchline': "but I'm clean now.",
'setup': 'I used to be addicted to soap...'},
{'punchline': "When it's ajar.", 'setup': 'When is a door not a door?'},
{'punchline': 'It was a waist of time.',
'setup': 'I made a belt out of watches once...'},
{'punchline': 'all I wanted was one night stand!',
'setup': 'This furniture store keeps emailing me,'},
{'punchline': 'Look for fresh prints.',
'setup': 'How do you find Will Smith in the snow?'},
{'punchline': 'It was pretty bad at first, but by the end I liked it.',
'setup': 'I just read a book about Stockholm syndrome.'},
{'punchline': "Dunno, they're just a bit shady.",
'setup': 'Why do trees seem suspicious on sunny days?'},
{'punchline': 'sky diving is not for you!',
'setup': "If at first you don't succeed"},
{'punchline': 'Rap', 'setup': "What kind of music do mummy's like?"},
{'punchline': 'I only have my shelf to blame.',
'setup': 'A book just fell on my head.'},
{'punchline': 'Bark bark.', 'setup': 'What did the dog say to the two trees?'},
{'punchline': 'are they guilty of resisting a rest?',
'setup': 'If a child refuses to sleep during nap time...'},
{'punchline': 'They mostly wrap.',
'setup': 'Have you ever heard of a music group called Cellophane?'},
{'punchline': 'Cliff.',
'setup': 'What did the mountain climber name his son?'},
{'punchline': "Because it's bound to squeal.",
'setup': 'Why should you never trust a pig with a secret?'},
{'punchline': "They're afraid to unwind.",
'setup': 'Why are mummys scared of vacation?'},
{'punchline': 'are remarkable.', 'setup': 'Whiteboards ...'},
{'punchline': 'A stega-snore-us.',
'setup': 'What kind of dinosaur loves to sleep?'},
{'punchline': 'A palm tree!', 'setup': 'What kind of tree fits in your hand?'},
{'punchline': 'but I turned myself around.',
'setup': 'I used to be addicted to the hokey pokey'},
{'punchline': 'Ten-tickles!',
'setup': 'How many tickles does it take to tickle an octopus?'},
{'punchline': 'A tuba toothpaste.',
'setup': 'What musical instrument is found in the bathroom?'},
{'punchline': 'I totally nailed it!',
'setup': 'My boss told me to attach two pieces of wood together...'},
{'punchline': 'Squash.', 'setup': 'What was the pumpkin’s favorite sport?'},
{'punchline': 'Kernel.',
'setup': 'What do you call corn that joins the army?'},
{'punchline': "but I just can't seem to get it going.",
'setup': "I've been trying to come up with a dad joke about momentum"},
{'punchline': 'Because they taste funny.',
'setup': "Why don't sharks eat clowns?"},
{'punchline': 'They were ribbiting.',
'setup': 'Just read a few facts about frogs.'},
{'punchline': 'Because they cantaloupe.',
'setup': 'Why didn’t the melons get married?'},
{'punchline': 'Microchips!', 'setup': 'What’s a computer’s favorite snack?'},
{'punchline': 'He had a hard drive.',
'setup': 'Why was the robot so tired after his road trip?'},
{'punchline': 'Someone cleaned out its cache!',
'setup': 'Why did the computer have no money left?'},
{'punchline': "I'm just not user friendly.", 'setup': "I'm not anti-social."},
{'punchline': 'Because it forgot to close windows.',
'setup': 'Why did the computer get cold?'},
{'punchline': 'The space bar!',
'setup': "What is an astronaut's favorite key on a keyboard?"},
{'punchline': "The used-car salesman KNOWS when he's lying.",
'setup': "What's the difference between a computer salesman and a used-car salesman?"},
{'punchline': 'call it version 1.0',
'setup': "If at first you don't succeed..."},
{'punchline': 'To get to the other slide!',
'setup': 'Why did Microsoft PowerPoint cross the road?'},
{'punchline': 'Had a byte!',
'setup': 'What did the computer do at lunchtime?'},
{'punchline': 'It had a virus!',
'setup': 'Why did the computer keep sneezing?'},
{'punchline': 'You look a bit flushed.',
'setup': 'What did one toilet say to the other?'},
{'punchline': 'Because it was framed.',
'setup': 'Why did the picture go to jail?'},
{'punchline': "I'll meet you at the corner.",
'setup': 'What did one wall say to the other wall?'},
{'punchline': 'Lonely',
'setup': 'What do you call a boy named Lee that no one talks to?'},
{'punchline': 'Because they are two-tired!',
'setup': 'Why do bicycles fall over?'},
{'punchline': 'It over swept!', 'setup': 'Why was the broom late?'},
{'punchline': 'The wheels, because they are always tired!',
'setup': 'What part of the car is the laziest?'},
{'punchline': 'Ever tried swatting a fly with a TV?',
'setup': "What's the difference between a TV and a newspaper?"},
{'punchline': "I think I'm coming down with something!",
'setup': 'What did one elevator say to the other elevator?'},
{'punchline': 'Because it held up some pants!',
'setup': 'Why was the belt arrested?'},
{'punchline': 'Because it has a lot of dates!',
'setup': 'What makes the calendar seem so popular?'},
{'punchline': "Because the bed won't come to you!",
'setup': 'Why do you go to bed every night?'},
{'punchline': 'A garbage truck!', 'setup': 'What has four wheels and flies?'},
{'punchline': 'He wanted to make a clean get away!',
'setup': 'Why did the robber take a bath before he stole from the bank?'},
{'punchline': 'It was the best damn program I’ve ever seen.',
'setup': 'Just watched a documentary about beavers.'},
{'punchline': 'woke up in the fireplace.',
'setup': 'Slept like a log last night'},
{'punchline': 'Because he was outstanding in his field.',
'setup': 'Why did the scarecrow win an award?'},
{'punchline': 'Because if it had four doors it would be a chicken sedan.',
'setup': 'Why does a chicken coop only have two doors?'},
{'punchline': 'About 5000 miles',
'setup': 'What’s the difference between an African elephant and an Indian elephant?'},
{'punchline': 'It got mugged.',
'setup': 'Why did the coffee file a police report?'},
{'punchline': 'He let out a little wine.',
'setup': 'What did the grape do when he got stepped on?'},
{'punchline': 'All of them.', 'setup': 'How many apples grow on a tree?'},
{'punchline': 'Roberto',
'setup': 'What name do you give a person with a rubber toe?'},
{'punchline': 'It’s fine, he woke up.',
'setup': 'Did you hear about the kidnapping at school?'},
{'punchline': 'Because if they fell forwards they’d still be in the boat.',
'setup': 'Why do scuba divers fall backwards into the water?'},
{'punchline': 'Igloos it together.',
'setup': 'How does a penguin build it’s house?'},
{'punchline': 'Roberto', 'setup': 'What do you call a man with a rubber toe?'},
{'punchline': 'Great food, no atmosphere.',
'setup': 'Did you hear about the restaurant on the moon?'},
{'punchline': 'For holding up a pair of pants!',
'setup': 'Why was the belt sent to jail?'},
{'punchline': 'He had a very esteemed colleague.',
'setup': 'Did you hear about the scientist who was lab partners with a pot of boiling water?'},
{'punchline': "He needs a jump. If that doesn't work he has to get it toad.",
'setup': 'What happens when a frogs car dies?'},
{'punchline': 'They rose.',
'setup': 'What did the flowers do when the bride walked down the aisle?'},
{'punchline': 'Because he couldn’t see that well.',
'setup': 'Why did the man fall down the well?'},
{'punchline': '...so I went home.',
'setup': 'My boss told me to have a good day...'},
{'punchline': 'By the bark.',
'setup': 'How can you tell it’s a dogwood tree?'},
{'punchline': 'It’s fine, he woke up.',
'setup': 'Did you hear about the kidnapping at school?'},
{'punchline': 'Because he Neverlands.',
'setup': 'Why is Peter Pan always flying?'},
{'punchline': 'Rhode Island.', 'setup': 'Which state has the most streets?'},
{'punchline': 'Alphawetical.',
'setup': 'What do you call 26 letters that went for a swim?'},
{'punchline': 'It was always so jaded.',
'setup': 'Why was the color green notoriously single?'},
{'punchline': 'To get his quarterback.',
'setup': 'Why did the coach go to the bank?'},
{'punchline': 'They have many fans.',
'setup': 'How do celebrities stay cool?'},
{'punchline': 'sadder day.',
'setup': "What's the most depressing day of the week?"},
{'punchline': 'But catscan.', 'setup': 'Dogs can’t operate MRI machines'},
{'punchline': 'but you guys didn’t like it.',
'setup': 'I was going to tell a time-traveling joke'},
{'punchline': 'instead look for a lighter.',
'setup': 'Stop looking for the perfect match'},
{'punchline': 'but he said it’s just a bug going around.',
'setup': 'I told my doctor I heard buzzing'},
{'punchline': 'A lamborghini.',
'setup': 'What kind of car does a sheep like to drive?'},
{'punchline': 'This is taxing.',
'setup': 'What did the accountant say while auditing a document?'},
{'punchline': 'It was loaf at first sight.',
'setup': 'What did the two pieces of bread say on their wedding day?'},
{'punchline': 'Because they cantaloupe.',
'setup': 'Why do melons have weddings?'},
{'punchline': 'Anna One, Anna Two!',
'setup': 'What did the drummer call his twin daughters?'},
{'punchline': 'A gummy bear!', 'setup': 'What do you call a toothless bear?'},
{'punchline': 'One says to the other, “Do you know how to drive this thing?”',
'setup': 'Two goldfish are in a tank.'},
{'punchline': '1forrest1', 'setup': 'What’s Forrest Gump’s password?'},
{'punchline': 'Resisting a rest.',
'setup': 'What is a child guilty of if they refuse to nap?'},
{'punchline': 'but none of them work.',
'setup': 'I know a lot of jokes about retired people'},
{'punchline': 'They can find everything on the web.',
'setup': 'Why are spiders so smart?'},
{'punchline': 'A bed.',
'setup': 'What has one head, one foot, and four legs?'},
{'punchline': 'Address.', 'setup': 'What does a house wear?'},
{'punchline': 'Red paint.', 'setup': 'What’s red and smells like blue paint?'},
{'punchline': 'but I don’t think they’ll fit me.',
'setup': 'My son asked me to put his shoes on'},
{'punchline': 'The neighbors keep demanding that I put it back.',
'setup': 'I’ve been bored recently, so I decided to take up fencing.'},
{'punchline': 'A loose Canon.',
'setup': 'What do you call an unpredictable camera?'},
{'punchline': 'Minnesota.',
'setup': 'Which U.S. state is known for its especially small soft drinks?'},
{'punchline': 'Nothing—they fast.',
'setup': 'What do sprinters eat before a race?'},
{'punchline': 'I can do it with my eyes closed.',
'setup': 'I’m so good at sleeping...'},
{'punchline': 'But I love their greatest hits!',
'setup': 'People are usually shocked that I have a Police record.'},
{'punchline': 'She seemed surprised.',
'setup': 'I told my girlfriend she drew on her eyebrows too high.'},
{'punchline': 'A lion.', 'setup': 'What do you call a fibbing cat?'},
{'punchline': 'Because it’s pointless.',
'setup': 'Why shouldn’t you write with a broken pencil?'},
{'punchline': 'sometimes he laughs.', 'setup': 'I like telling Dad jokes…'},
{'punchline': 'In Instagrams.', 'setup': 'How do you weigh a millennial?'},
{'punchline': 'even the cake was in tiers.',
'setup': 'The wedding was so beautiful'},
{'punchline': 'Flag football.', 'setup': 'What’s the most patriotic sport?'}]


export default jokes;