export const DownloadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7 9L12 14M12 14L17 9M12 14L12 5" stroke="#33363F" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M5 16L5 17C5 18.1046 5.89543 19 7 19L17 19C18.1046 19 19 18.1046 19 17V16" stroke="#33363F"
              strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
    );
};

export default DownloadIcon;