const FolderIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_35_67" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                  height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_35_67)">
                <path
                    d="M3.59253 9C3.59253 7.11438 3.59253 6.17157 4.17832 5.58579C4.7641 5 5.70691 5 7.59253 5H7.93568C8.75318 5 9.16193 5 9.52947 5.15224C9.89701 5.30448 10.186 5.59351 10.7641 6.17157L11.421 6.82843C11.999 7.40649 12.288 7.69552 12.6556 7.84776C13.0231 8 13.4319 8 14.2494 8H15.5925C17.4781 8 18.421 8 19.0067 8.58579C19.5925 9.17157 19.5925 10.1144 19.5925 12V15C19.5925 16.8856 19.5925 17.8284 19.0067 18.4142C18.421 19 17.4781 19 15.5925 19H7.59253C5.70691 19 4.7641 19 4.17832 18.4142C3.59253 17.8284 3.59253 16.8856 3.59253 15V9Z"
                    stroke="black" strokeWidth="1.5"/>
            </g>
        </svg>
    )
}

export default FolderIcon;