import CheckIcon from "./CheckIcon";
import ChevronDownIcon from "./ChevronDownIcon";
import DiscIcon from "./DiskIcon";
import DownloadIcon from "./DownloadIcon";
import FolderIcon from "./FolderIcon";
import HistoryIcon from "./HistoryIcon";
import ProfileIcon from "./ProfileIcon";
import RepetitionIcon from "./RepetitionIcon";
import StudyModeIcon from "./StudyModeIcon";
import TrashIcon from "./TrashIcon";

const Icon = ({ name }) => {
    switch (name) {
        case "ChevronDownIcon":
            return <ChevronDownIcon />;
        case "DiskIcon":
            return <DiscIcon />;
        case "DownloadIcon":
            return <DownloadIcon />;
        case "TrashIcon":
            return <TrashIcon />;
        case "FolderIcon":
            return <FolderIcon />;
        case "ProfileIcon":
            return <ProfileIcon />;
        case "StudyModeIcon":
            return <StudyModeIcon />;
        case "HistoryIcon":
            return <HistoryIcon />;
        case "RepetitionIcon":
            return <RepetitionIcon />;
        case "CheckIcon":
            return <CheckIcon />;
        default:
            return "";
    }
};

export default Icon;
