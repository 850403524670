import "font-awesome/css/font-awesome.min.css";
import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Icon from "../Icon";
import FlashCardEditor from "./FlashCardEditor";
import styles from "./flashcardEditMode.module.css";

import StudyCard from "./StudyCard";

const EditCard = ({
    headline,
    onHeadlineChange,
    card,
    text,
    onTextChange,
    saveCard,
    deleteCard,
    cardString,
    savingCardIds,
    finishedSavingCardIds,
}) => {
    const formatTextForDisplay = (text) => {
        return text.replace(/\n/g, "<br />");
    };

    return (
        <div className={styles.container} onBlur={saveCard}>
            <div className={styles.cardColoring}></div>
            <div className={styles.content}>
                <div className={styles.cardHeadline}>
                    <span
                        className={styles.headlineInput}
                        contentEditable="true"
                        placeholder={"Title of your card"}
                        suppressContentEditableWarning={true}
                        onBlur={(e) => onHeadlineChange(e)}
                        dangerouslySetInnerHTML={{ __html: formatTextForDisplay(headline) }}></span>
                    <div className={styles.number}>{cardString}</div>
                </div>
                <div
                    className={styles.cardContent}
                    tabIndex="0" // Make the div focusable
                    >
                    <FlashCardEditor text={text} onTextChange={onTextChange}></FlashCardEditor>
                    <div className={styles.controlIcons}>
                        {savingCardIds.includes(card.id) ? (
                            <span className={styles.icon}>
                                <ClipLoader className={styles.cliploader} />
                            </span>
                        ) : finishedSavingCardIds.includes(card.id) ? (
                            <span className={styles.icon}>
                                <Icon name="CheckIcon" />
                            </span>
                        ) : (
                            <span className={styles.icon} onClick={saveCard}>
                                <Icon name="DiskIcon" />
                            </span>
                        )}
                        <span className={styles.icon} onClick={deleteCard}>
                            <Icon name="TrashIcon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Flashcard = ({
    card,
    isStudyModeActive,
    modifyCard,
    savingCardIds,
    finishedSavingCardIds,
    cardNumber,
    numberOfCards,
    numberOfRepetitions,
    showAnswer,
}) => {
    const [headline, setHeadline] = useState(card.headline);
    const [text, setText] = useState(card.content);

    const cardString = `${cardNumber}/${numberOfCards}`;

    const handleHeadlineChange = (e) => {
        const headline = e.target.innerHTML;
        const formattedHeadline = headline
            .replace(/<div>/g, "\n")
            .replace(/<\/div>/g, "")
            .replace(/<br>/g, "\n")
            .replace(/&nbsp;/g, " ");
        setHeadline(formattedHeadline);
    };

    const handleContentChange = (content) => {
        setText(content);
    }

    const useLazyLoad = (ref, options = {}) => {
        const [isVisible, setIsVisible] = useState(isStudyModeActive ? true : false);

        useEffect(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        try {
                            observer.unobserve(ref.current); // Stop observing once loaded
                        } catch {}
                    }
                });
            }, options);

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.disconnect();
                }
            };
        }, [ref, options]);

        return isVisible;
    };

    const ref = useRef();
    const isVisible = useLazyLoad(ref);

    const saveCard = () => {
        console.log("saveCard triggered");
        modifyCard(card.id, "saveCard", headline, text);
    };

    const deleteCard = () => {
        modifyCard(card.id, "deleteCard");
    };

    return (
        <div ref={ref} className={styles.wrapper}>
            {isVisible &&
                (!isStudyModeActive ? (
                    <EditCard
                        headline={headline}
                        onHeadlineChange={handleHeadlineChange}
                        text={text}
                        onTextChange={handleContentChange}
                        card={card}
                        saveCard={saveCard}
                        deleteCard={deleteCard}
                        cardString={cardString}
                        savingCardIds={savingCardIds}
                        finishedSavingCardIds={finishedSavingCardIds}
                    />
                ) : (
                    <StudyCard
                        headline={headline}
                        text={text}
                        setText={setText}
                        card={card}
                        numberOfRepetitions={numberOfRepetitions}
                        showAnswer={showAnswer}
                        correctAmount={card.correct}
                        cardString={cardString}
                    />
                ))}
        </div>
    );
};

export default Flashcard;
