const TextPickerIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
                d="M9 4H7C5.58579 4 4.87868 4 4.43934 4.43934C4 4.87868 4 5.58579 4 7V9"
                stroke="#222222"
                strokeLinecap="round"
            />
            <path
                d="M9 20H7C5.58579 20 4.87868 20 4.43934 19.5607C4 19.1213 4 18.4142 4 17V15"
                stroke="#222222"
                strokeLinecap="round"
            />
            <path
                d="M15 20H17C18.4142 20 19.1213 20 19.5607 19.5607C20 19.1213 20 18.4142 20 17V15"
                stroke="#222222"
                strokeLinecap="round"
            />
            <path
                d="M14 6.5C16 5 16 5 17 2C18 5 18 5 20 6.5C18 8 18 8 17 10.5C16 8 16 8 14 6.5Z"
                stroke="#33363F"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TextPickerIcon;
