import React from "react";
import FlashCardEditor from "./FlashCardEditor";
import studystyles from "./StudyCard.module.css";
import styles from "./flashcardEditMode.module.css";

const StudyCard = ({
    headline,
    numberOfRepetitions,
    showAnswer,
    card,
    text,
    setText,
    cardString,
}) => {
    const cardTitle = headline || "Title of your Card";

    const formatTextForDisplay = (text) => {
        return text.replace(/\n/g, "<br />");
    };

    const StudyCardOnlyTitle = () => (
        <>
            <div className={studystyles.number}>{cardString}</div>
            <div
                className={studystyles.headlineLarge}
                dangerouslySetInnerHTML={{ __html: formatTextForDisplay(cardTitle) }}></div>
            <div className={studystyles.number}>
                {card.correct}/{numberOfRepetitions}
            </div>
        </>
    );

    const StudyCardWithAnswer = () => (
        <>
            <div className={styles.cardHeadline}>
                <div
                    className={studystyles.headline}
                    dangerouslySetInnerHTML={{ __html: formatTextForDisplay(cardTitle) }}></div>
                <div className={styles.number}>{cardString}</div>
            </div>
            <div className={styles.cardContent}>
                <FlashCardEditor text={text} setText={setText} isReadOnly={true}></FlashCardEditor>
                <div className={styles.controlIcons}>
                    {card.correct}/{numberOfRepetitions}
                </div>
            </div>
        </>
    );

    return (
        <div className={studystyles.container}>
            <div className={studystyles.content}>
                {showAnswer ? (
                    <StudyCardWithAnswer />
                ) : (
                    // hideAnswer
                    <StudyCardOnlyTitle />
                )}
            </div>
            <div className={studystyles.cardColoring}></div>
        </div>
    );
};

export default StudyCard;
