import FullscreenIcon from "./FullscreenIcon";
import MarkerIcon from "./MarkerIcon";
import TextPickerIcon from "./TextPickerIcon";
import ZoomInIcon from "./ZoomInIcon";
import ZoomOutIcon from "./ZoomOutIcon";

const PdfIcon = ({ name }) => {
    switch (name) {
        case "fullscreen":
            return <FullscreenIcon />;
        case "zoom-in":
            return <ZoomInIcon />;
        case "zoom-out":
            return <ZoomOutIcon />;
        case "marker":
            return <MarkerIcon />;
        case "text-picker":
            return <TextPickerIcon />;
        default:
            throw new Error("Unknown PDF-Icon name: " + name);
    }
};

export default PdfIcon;
