import React from 'react';
import styles from './repetitionsbutton.module.css';
import Icon from "../Icon";


function RepetitionButton(props) {
  const { title, icon, dropdownContent, onDropdownClick, isOpen, onToggle, selectedRepetitions} = props;

  return (
    <div className={styles.dropdown}>
      <button className={styles.button} onClick={onToggle}>
            <Icon name="RepetitionIcon" className={styles.icon} />
        {title ? <span className={styles.title}>{title}</span> : null}
        <Icon name="ChevronDownIcon" className={styles.icon}></Icon>
      </button>
      {isOpen && (
        <div className={styles.dropdownContent}>
          {dropdownContent.map((item, index) => (
            <button
              key={index} className={parseInt(selectedRepetitions) === parseInt(item.label) ? styles.selected : "" }
              onClick={() => onDropdownClick(item.label)}
            >
              {item.label} Repetitions
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default RepetitionButton;
